<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-20px">

        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Notice Board</div>
            </div>
            <div class="page-rightheader ms-md-auto">
                <div class="d-flex align-items-end flex-wrap my-auto end-content breadcrumb-end">
                    <div class="btn-list">
                        <a href="javascript:void(0);" class="btn btn-primary me-3" data-bs-toggle="modal"
                            data-bs-target="#addnoticemodals">Add New Notice</a>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="w-100" style="padding: 0;">

            <div class="card-body row justify-content-between" style="margin-bottom:-10px">
                <!-- <div class="col-xs-9 col-md-9 col-sm-9 col-8 " style="padding: 0;">

                    <div class="my-3"> -->
<!-- 
                        <div class="page-leftheader">
                            <div class="page-title">Notice Board</div>
                        </div> -->

                    <!-- </div>
                </div> -->

                <div class="col-xs-3 col-md-3 col-sm-3 col-4 my-3" style="padding: 0;">
                    <button class="btn btn-primary w-100 px-0" (click)="addNewData()" data-bs-target="#addnoticemodals" data-bs-toggle="modal">
                        Add New Notice
                    </button>
                </div>


            </div>

        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Notice Summary</h4>
                    </div>
                    <div class="card-body ">
                        <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                            [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                            (scrolled)="onScroll()" id="scrolllength">
                            <div class="table-responsive">
                                <table class="table table-hover  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-notice">
                                    <thead class="tablerow">
                                        <tr class=" stickyatTop rowcolors">
                                            <th class="border-bottom-0 w-5" scope="col">No</th>
                                            <th class="border-bottom-0" scope="col">Title</th>
                                            <th class="border-bottom-0" scope="col">Description</th>
                                            <th class="border-bottom-0" scope="col">From date</th>
                                            <th class="border-bottom-0" scope="col">To date</th>
                                            <!-- <th class="border-bottom-0">To</th> -->
                                            <th class="border-bottom-0" scope="col">Created On</th>
                                            <th class="border-bottom-0" scope="col">Action</th>

                                            <!-- <th class="border-bottom-0">Status</th> -->
                                            <!-- <th class="border-bottom-0">Action</th> -->
                                        </tr>
                                    </thead>    
                                    <tbody>
                                        <tr *ngFor="let notice of renderNoticeList;let i=index">
                                            <td data-label="Sl No." [scope]="i!==0 ? '': 'row'">{{i+1}}</td>
                                            <td class="text-wrap" style="min-width:10vw;max-width:14vw;"  data-label="Title">{{notice.title}}</td>
                                            <td class="text-wrap" style="min-width:10vw;max-width: 20vw;" data-label="Description">{{ notice.description }}</td>
                                            <td data-label="From date">{{notice.fromdate | date : 'medium'}}
                                            </td>
                                            <td data-label="To date">{{notice.todate | date :'medium'}}</td>
                                            <td data-label="Created On">{{notice.createdtime | date :'mediumDate'}}</td>
                                         
                                            <td>
                                                <button class="btn btn-primary btn-icon btn-sm me-2" (click)="noticeboardEdit(notice)"
                                                  data-method="edit" title="Edit">
                                                  <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                                </button>
                      
                      
                                                <button class="btn btn-danger btn-icon btn-sm me-2" (click)="noticeboardDelete(notice.id)"
                                                  data-method="delete" title="Delete">
                                                  <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                                </button>
                                              </td>
                                              </tr>
                                         
                                            <!-- <td>
                                                <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    (click)="noticeboardEdit(notice)" data-method="edit"
                                                    title="Edit">
                                                    <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button>                                     -->
                                            
                                                <!-- <button class="btn btn-primary btn-icon btn-sm me-2 " data-bs-target="#addnoticemodals" data-bs-toggle="modal">
                                                    <i class="ion-wand" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button> (click)="noticeboardDelete(notice.id)"-->
                                                <!-- <button class="btn btn-danger btn-icon btn-sm me-2"
                                                (click)="noticeboardDelete(notice.id)"  data-method="delete" data-bs-dismiss="modal"
                                                    title="Delete" data-bs-target="#showAlertWarning">
                                                    <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button> -->
                                                <!-- <div class="ms-auto"> -->
                                                   

                                                    <!-- <button class="btn btn-danger btn-simple " data-bs-method="delete" rel="tooltip" title="Delete"
                                                     type="button" data-bs-toggle="modal" data-bs-target="#showAlertWarning" (click)="noticeboardDelete(notice.id)">
                                                      <span class="docs-tooltip" data-bs-toggle="tooltip">
                                                        <span class="fa fa-trash"></span>
                                                      </span>
                                                    </button> -->
                                                  <!-- </div> -->
                                            <!-- </td> -->
                                            <!-- <td><span class="badge badge-success">Active</span></td> -->
                                            <!-- <td>
                                            <div class="d-flex">
                                            <a  href="javascript:void(0);" class="action-btns1" data-bs-toggle="modal" data-bs-target="#editnoticemodal"><i class="feather feather-eye primary text-primary"  data-bs-toggle="tooltip" data-bs-placement="top" title="Edit/View"></i></a>
                                            <a  href="javascript:void(0);" class="action-btns1" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i class="feather feather-trash-2 text-danger"></i></a>
                                            </div>
                                            </td> -->
                                        <!-- </tr> -->

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>


</div>

    <!-- <div class="modal fade" id="showAlertWarning" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Delete Notice</h4>
        </div>
        <div class="modal-body">
          <p>Do You Want to Delete This Notice ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-primary medium" (click)="noticeDelete()" data-bs-dismiss="modal">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div> -->


  <div id="showAlert" class="modal" style="top: 40px;">
    <div class="modal-dialog">
      <div class="modal-content animate">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="close()">×</button>
          <h4 class="modal-title">Warning</h4>
        </div>

        <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
          <!-- auto scroll -->
          <span><b> Do You Want to Delete This Notice ?</b></span>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="close()">Cancel</button>
          <button type="button" class="btn btn-danger medium" (click)="noticeDelete()">Yes</button>

        </div>
      </div>
    </div>
  </div>


<div class="modal fade" id="addnoticemodals">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Notice</h5>
                <button class="btn-close" (click)="closemodal($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" (ngSubmit)="form.form.valid && saveNoticeInfo(form.form.valid)"
                    #form="ngForm">
                    <div class="alertcomp"></div>
                    <div class="form-group">
                        <label class="form-label">Title</label>
                        <input class="form-control" placeholder="Enter Title" id="title" name="title"
                            [(ngModel)]="savenoticeinfo.title" #title="ngModel" required>
                        <p *ngIf="submitted && !title.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Title is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf32">From Date:</label>
                        <input type="datetime-local" class=" form-control" id="fromdate" name="fromdate"
                            [(ngModel)]="savenoticeinfo.fromdate" #fromdate="ngModel" required
                            (ngModelChange)="from_date_change_listner($event , fromdate)">
                        <p *ngIf="submitted && !fromdate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ From Date is required
                        </p>
                        <!-- <p class="help-block" style="color: tomato;">ⓘ From date is not Valid</p> -->
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf13">To Date:</label>
                        <input type="datetime-local" class=" form-control" id="todate" name="todate"
                            [(ngModel)]="savenoticeinfo.todate" #todate="ngModel" required
                            (ngModelChange)="to_date_change_listner($event)">
                        <p *ngIf="submitted && !todate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ To Date is required
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="description">Description:</label>
                        <textarea name="text" class="form-control" id="description" name="description"
                            [(ngModel)]="savenoticeinfo.description" #description="ngModel" required></textarea>
                        <p *ngIf="submitted && !description.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Description is required
                        </p>
                    </div>
                </form>
                <!-- <div class="form-group">
                    <label class="form-label">Attachment:</label>
                    <div class="form-group">
                    <label for="form-label" class="form-label"></label>
                        <input class="form-control" type="file">
                    </div>
                </div> -->

            </div>
            <div class="modal-footer">
                <button class="btn btn-success" *ngIf="!isEditing" (click)="saveNoticeInfo(form.form.valid)">Save</button>
                <button class="btn btn-success" *ngIf="isEditing" (click)="updateNoticeInfo(form.form.valid)">Update</button>
                <button class="btn btn-secondary" (click)="closemodal($event)">Close</button>
              </div>
              
        </div>
    </div>
</div>




<!-- change to update save  -->

<!-- <div class="modal fade" id="editnoticemodals">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Notice</h5>
                <button class="btn-close" (click)="closemodale($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" (ngSubmit)="form.form.valid && updateNoticeInfo(form.form.valid)"
                    #form="ngForm">
                    <div class="alertcomp"></div>
                    <div class="form-group">
                        <label class="form-label">Title</label>
                        <input class="form-control" placeholder="Enter Title" id="title" name="title"
                            [(ngModel)]="updatenoticeinfo.title" #title="ngModel" required>
                        <p *ngIf="submitted && !title.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Title is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf32">From Date:</label>
                        <input type="datetime-local" class=" form-control" id="fromdate" name="fromdate"
                            [(ngModel)]="updatenoticeinfo.fromdate" #fromdate="ngModel" required
                            (ngModelChange)="from_date_change_listner($event , fromdate)">
                        <p *ngIf="submitted && !fromdate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ From Date is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf13">To Date:</label>
                        <input type="datetime-local" class=" form-control" id="todate" name="todate"
                            [(ngModel)]="updatenoticeinfo.todate" #todate="ngModel" required
                            (ngModelChange)="to_date_change_listner($event)">
                        <p *ngIf="submitted && !todate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ To Date is required
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="description">Description:</label>
                        <textarea name="text" class="form-control" id="description" name="description"
                            [(ngModel)]="updatenoticeinfo.description" #description="ngModel" required></textarea>
                        <p *ngIf="submitted && !description.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Description is required
                        </p>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="closemodale($event)">Close</button>
                <button class="btn btn-success" (click)="updateNoticeInfo(form.form.valid )">Update</button>
             </div>
        </div>
    </div>
</div> -->