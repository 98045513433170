import { Component, OnInit } from "@angular/core";
import { DocumentInfoService } from '../../services/documentinfo.service';

@Component({
    selector: 'rulesAndPolicy',
    templateUrl: './rulesAndPolicy.component.html',
})

export class RulesAndPolicyComponent implements OnInit {

    //listOfRuleAndPolicy : OrgFilesInfo;

    listOfRuleAndPolicy = [];
    fileSavedPath: any;
    type = null;
    f_Name: any;
    showRemainingTasks:boolean = false;

    constructor(private docmentInfoService: DocumentInfoService) { }

    ngOnInit() {
        //this.listOfRuleAndPolicy = new OrgFilesInfo();

        this.getTheRulesAndPolicy();
    }


    getTheRulesAndPolicy() {
        this.docmentInfoService.getRulesAndPolicy().subscribe(
            data => {
                console.log(this.listOfRuleAndPolicy)
                this.listOfRuleAndPolicy = data.orgFileList;
                this.listTheRulesAndPolicy();
            },
            error => {
                console.log("The Error is ", error);
            }
        );

    }

    listTheRulesAndPolicy() {
        console.log("The Length of ListOfRulesAndPolicy", this.listOfRuleAndPolicy);
    }


    downloadRulesAndPolicy(filName: any, path: any) {
        this.fileSavedPath = path;
        console.log("The File Saved Path is", this.fileSavedPath);
        this.f_Name = filName;
        this.downloadPolicy();
    }


    downloadPolicy() {

        console.log("Download Image is called");
        this.docmentInfoService.downloadRulesAndPolicy(this.fileSavedPath).subscribe(
            data => {
                var downloadUrl = URL.createObjectURL(data);
                var link = document.createElement("a");
                link.download = this.f_Name;
                console.log("File Name is ", this.f_Name);
                link.href = downloadUrl;
                link.click();
                link.remove();
                console.log("The Url is", downloadUrl);
            },
            error => {
                console.log('Error occured in download file');
            }
        );

    }

    toggleRemainingTasks() {
        console.log('Toggling tasks visibility');
        this.showRemainingTasks = !this.showRemainingTasks;
        console.log('showRemainingTasks:', this.showRemainingTasks);
      }


}