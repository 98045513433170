import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';


@Injectable({
	providedIn: 'root'
})
export class reviewpayslipService {

	protected basePath = '/api';
	public defaultHeaders: Headers = new Headers();
	public url;

	constructor(private service: CommonService) { }

	public getReviewPaySlips(fromDate: any, toDate: any): Observable<any> {
		this.url = '/v1/reviewPaySlips';
		const path = this.basePath + this.url;
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('fromDate', fromDate);
		queryParameters = queryParameters.set('toDate', toDate);
		return this.service.getByQueryParamWithHttpInfo(queryParameters, path);
	}

	public approveRejectReviewPayslips(reviewedPaySlips: any, isApprove: any, comments: any): Observable<any> {
		this.url = '/v1/approvereviewPaySlips';
		const path = this.basePath + this.url;
		let queryParameters = new HttpParams();
		queryParameters = queryParameters.set('isApprove', isApprove);
		queryParameters = queryParameters.set('comments', comments);
		console.log(JSON.stringify(reviewedPaySlips));
		return this.service.postWithHttpInfo(reviewedPaySlips, queryParameters, path);
	}

	public generatePayslip(info: any): Observable<any> {
		console.log("value sent generatePayslip : ", info)
		this.url = '/v1/generatePaySlipbyMonth';
		const path = this.basePath + this.url;
		let queryParameters = new HttpParams();
		return this.service.postWithHttpInfo(info, queryParameters, path);
	}

	public fetchPaySlip(info: any): Observable<any> {
		console.log("value sent fetchPaySlip : ", info)
		this.url = '/v1/fetchPaySlipbyMonth';
		const path = this.basePath + this.url;
		let queryParameters = new HttpParams();
		return this.service.postWithHttpInfo(info, queryParameters, path);
	}


}