<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="card-body px-0"> -->
        <!-- <div class="page-leftheader my-3">
                <div class="page-title">Annual Holiday Master</div>
            </div> -->
        <!-- </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">


                <form id="fcForm" class="form-horizontal form-label-left row" #annualform="ngForm">
                    <div class="card">
                        <div class="card-body row px-0">
                            <div class="row col-md-6 col-sm-6 col-xs-6 px-2">
                                <div class="form-group label-floating  px-3">
                                    <label class="form-label">Add New Holiday</label>

                                    <div>
                                        <input type="text" name="annual_name"
                                            class="form-control col-md-12 col-xs-12 field_space" autocomplete="off"
                                            placeholder="Enter Annual holiday Name" autofocus="true"
                                            [(ngModel)]="annualName" #annual_name="ngModel"
                                            [ngClass]="(!annual_name.valid && annual_name.touched) ? 'is-invalid' : '' " />
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!annual_name.valid && annual_name.touched)">Enter Annual holiday
                                        Name</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6 p2 justify-content-between align-items-start">
                                <div class="form-group label-floating">
                                    <label class="form-label">&nbsp;</label>
                                    <button *ngIf="addOrUpdate; else updateButton" type="button" id="navigatetarget"
                                        class="btn btn-primary w-40" style="margin-right: 3vw;margin-left: 2vw;"
                                        (click)="addAnnualHolidayButton()">
                                        Add
                                    </button>
                                    <ng-template #updateButton>
                                        <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                            style="margin-right: 3vw;margin-left: 2vw;" (click)="updateAnnualHoliday()">
                                            Update
                                        </button>
                                    </ng-template>
                                    <button type="button" class="btn btn-danger w-40"
                                        (click)="viewAnnualHolidayButton()">
                                        View
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"> </div>


                    <div class="empty-div-one" style="height: 1px;" id="navigatedest"></div>

                    <div class="card" *ngIf="add_view_toggle == 'addAnnualHoliday' ">
                        <div class="card-body">


                            <div class="row col-md-12 col-sm-12 col-xs-12">

                                <!--annual holiday name  -->
                                <div class=" row col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Annual Holiday Name</label>

                                            <div>
                                                <input type="text" name="annual_name"
                                                    class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                    placeholder="Enter Annual holiday Name" autofocus="true"
                                                    [(ngModel)]="annualName" required #annual_name="ngModel"
                                                    [ngClass]="(!annual_name.valid && annual_name.touched) ? 'is-invalid' : ''" />

                                            </div>
                                            <span class="help-text text-danger"
                                                *ngIf="(!annual_name.valid && annual_name.touched)">Enter
                                                Annual
                                                Holiday Name</span>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Date</label>

                                            <div>
                                                <input class="form-control" type="date" name="Date" autocomplete="off"
                                                    autofocus="true" [disabled]="false" [(ngModel)]="Date" required
                                                    #date="ngModel"
                                                    [ngClass]="(!date.valid && date.touched) ? 'is-invalid' : ''" />
                                            </div>
                                            <span class="help-text text-danger"
                                                *ngIf="(!date.valid && date.touched)">Enter Date </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"> </div>

                                <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                                    <div class="w-100">
                                        <label class="form-label">Credited day</label>
                                    </div>
                                    <select class="form-control" data-style="btn btn-drpdwn btn-round" id="creditedDay"
                                        name="creditedDay" [(ngModel)]="creditedDay">
                                        <option value="F">Full Day</option>
                                    </select>

                                </div> -->

                                <!-- <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Color Code</label>
                                        <div class="w-100">
                                            <input type="color" [(ngModel)]="colorCode" />
                                            <div class="selected-color" [style.background]="colorCode">
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                        </div>

                    </div>
                    <div class="actionBar pe-4 ps-4"  *ngIf="add_view_toggle=='addAnnualHoliday'">
                        <div class="clearfix"> </div>
                        <!--  -->
                        <button *ngIf="addOrUpdate" class="btn btn-success" type="submit"
                            (click)="addAnnualHoliday()">Save</button>

                        <button *ngIf="addOrUpdate" class="btn btn-danger ms-2 " type="clear"
                            (click)=" clear()">Clear</button>
                    </div>

                    <div class="card top_margin px-0">
                        <div class="col-md-12 col-sm-12 col-xs-12 px-0" *ngIf="add_view_toggle == 'viewAnnualHoliday' ">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">List Of Annual Holidays</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <div class="scrollsearchresults" id="scroll" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                            [infiniteScrollThrottle]="100" [scrollWindow]="false" id="scrolllength">

                                            <table
                                                class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                id="hr-table">
                                                <thead class="tablerow">
                                                    <tr class="rowcolors stickyatTop">
                                                        <th class="border-bottom-0 w-5 fs-6 ">Sl.No</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Annual Holiday Name
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Date</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="max-height: 50vh; overflow: scroll;">
                                                    <tr *ngFor="let an of annualhoildaylist; let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{an.reason}}</td>
                                                        <td>{{an.date | date: 'mediumDate'}}</td>
                                                        <td>
                                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                data-method="edit" title="Edit"
                                                                (click)="editAnnualHoliday(an)">
                                                                <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                            <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                data-method="delete" title="Delete"
                                                                data-bs-target="#showAlertWarning"
                                                                (click)="deleteAnnualHoliday(an.annualHolidayID, an.reason)">
                                                                <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>