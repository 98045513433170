import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentInfo } from '../models/departmentInfo';
import { EmployeeGroup } from '../models/Employeegroup';
import { Groupinfo } from '../models/Groupinfo';
import { CommonService } from '../services/common.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeShiftService } from '../services/employee-shift.service';
declare var $: any;

interface EmpDepartmentGroup {
  EmployeeName: string;
  department: string;
  group: string;
}


@Component({
  selector: 'app-employeegroup',
  templateUrl: './employeegroup.component.html',
  styleUrls: ['./employeegroup.component.css']
})
export class EmployeegroupComponent implements OnInit {

  group: Groupinfo;
  departments: DepartmentInfo[];
  query: string;
  employeeMasterLucene: any[];
  employeedepgroup: EmployeeGroup[] = [];
  searchby!: string;
  groupid: number = 0;

  department: number = 0;

  req_employeedepgroup: EmployeeGroup[] = [];

  limit: number;
  offset: number;

  constructor(private render: Renderer2, private empLeaveService: EmployeeLeaveService, private shiftService: EmployeeShiftService, private common: CommonService, private route: Router) { }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0
    this.getdepartment();
    this.getgroups();
    this.searchby = "departmentgroup"


    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })

  }

  getgroups() {
    this.shiftService.getgroup().subscribe({
      next: (res) => {
        this.group = res.groups
      },
      error: (e) => {
        console.log("error occured while getting all the groups ")
      }
    })
  }

  getdepartment() {
    this.common.getSearchdeparment1().subscribe({
      next: (res) => {
        this.departments = res;
      },
      error: (e) => {
        console.log("error occured while getting all the departments")
      }
    })
  }
  getEmployeeByNameOrId() {
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMasterLucene = []
    }
    if (this.query.length >= 3) {

      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMasterLucene = data.employeeMasterData

          // if(this.employeeMaster.length > 0){
          // 	const autoCompleteId = document.getElementById("employeeMaster");
          // 	autoCompleteId.classList.remove("auto-complete-active");
          // }
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  getselectedOption(e) {
    console.log(e.target.value)
    this.searchby = e.target.value;
  }

  modelGroup(e) {
    console.log(this.groupid)
    this.limit = 10;
    this.offset = 0;
  }

  modelDepartment(e) {
    console.log(this.department, e)
    this.limit = 10;
    this.offset = 0;
  }

  search(e: EmpDepartmentGroup) {

    // console.log("List Emp Emp Department", this.req_employeedepgroup)
    // console.log("T/F Emp Group", this.isGroupchanges)
    // console.log("Emp Emp Department", e.department, this.department)
    // console.log("Emp Group", e.group, this.groupid)

    if ((Number(e.department) || Number(e.group))) {
      console.log(this.req_employeedepgroup, e.department, e.group, "isnull");

      this.req_employeedepgroup = []

      // console.log("It loop >> One >>")
    }
    if (Number(e.department) && Number(e.group) || ((Number(e.department) == 0) && Number(e.group)==0)) {

      console.log("all");
      console.log(e.department, e.group, Number(e.department), Number(e.group));

      // console.log("It loop >> Two >>")
      // console.log("List Emp Emp Department", this.req_employeedepgroup)
      // console.log("T/F Emp Group", this.isGroupchanges)
      // console.log("Emp Emp Department", e.department, this.department)
      // console.log("Emp Group", e.group, this.groupid)

      this.shiftService.getempbasedonDepartmentandgroup(Number(e.department), Number(e.group), this.limit, this.offset).subscribe(
        {
          next: (res) => {
            // this.employeedepgroup = [];
            if (this.offset == 0) {
              this.employeedepgroup = res

            } else {
              this.employeedepgroup = this.employeedepgroup.concat(res);
            }

            if (this.employeedepgroup.length == 0) {
              this.alertmessages("No Records Found", "danger");
            }
            this.scrollLength()
          },
          error: (e) => {
            console.log(e);
          }
        }
      )
    } else
      // search based on only department
      if (Number(e.department)) {
        console.log(this.limit, this.offset)
        this.shiftService.getempbasedonDepartment(Number(e.department), this.limit, this.offset).subscribe(
          {
            next: (data) => {
              // this.employeedepgroup = data;
              console.log(data)
              if (this.offset == 0) {

                this.employeedepgroup = data

              } else {
                this.employeedepgroup = this.employeedepgroup.concat(data);
              }
              this.scrollLength()

            },
            error: (error) => {
              console.log(error)
            },
            complete: () => {

            }
          }
        )

      } else if (Number(e.group)) {
        this.shiftService.getempbasedonGroup(Number(e.group), this.limit, this.offset).subscribe({
          next: (data) => {
            // this.employeedepgroup = data;
            console.log(data)
            if (this.offset == 0) {

              this.employeedepgroup = data

            } else {
              this.employeedepgroup = this.employeedepgroup.concat(data);
            }
            this.scrollLength()

          },
          error: (error) => {
            console.log(error)
          },
          complete: () => {

          }
        })
      } 
  }

  isGroupchanges: boolean = false;
  getgroupchanges(e, empdepGrp: EmployeeGroup) {
    console.log(e, empdepGrp.groupid, Number(e.target.value));
    if (Number(e.target.value)) {
      if (Number(e.target.value) !== empdepGrp.groupid) {
        this.isGroupchanges = true
      }
      empdepGrp.groupid = Number(e.target.value);

      if (this.req_employeedepgroup.length === 0) {
        if (Number(empdepGrp.id) !== 0) {
          empdepGrp.isgroupchange = true;
        }
        this.req_employeedepgroup.push(empdepGrp);
      } else {
        let index = -1;
        console.log(empdepGrp.isgroupchange)
        if (Number(empdepGrp.id) !== 0) {
          empdepGrp.isgroupchange = true;
        }
        for (let i = 0; i < this.req_employeedepgroup.length; i++) {
          if (this.req_employeedepgroup[i].uniqueemployeeid === Number(empdepGrp.uniqueemployeeid) && this.req_employeedepgroup[i].groupid === Number(empdepGrp.groupid)) {
            if (empdepGrp.isgroupchange === true) {
              empdepGrp.isgroupchange = false;
            }
            index = i;
          }

        }


        if (index > -1) {
          this.req_employeedepgroup.splice(index, 1)
          this.req_employeedepgroup.push(empdepGrp)
        } else {
          this.req_employeedepgroup.push(empdepGrp);
        }
      }



    } else {
      console.log(e, empdepGrp, Number(e.target.value));
      let index = -1;
      for (let i = 0; i < this.req_employeedepgroup.length; i++) {
        console.log(this.req_employeedepgroup[i].uniqueemployeeid, empdepGrp.uniqueemployeeid, this.req_employeedepgroup[i].groupid,
          empdepGrp.groupid)
        if (this.req_employeedepgroup[i].uniqueemployeeid === Number(empdepGrp.uniqueemployeeid)) {
          index = i;
        }

      }

      // if(index>-1){
      this.req_employeedepgroup.splice(index, 1)
      // this.req_employeedepgroup.push(empdepGrp)
      // }else{
      // this.req_employeedepgroup.push(empdepGrp);
      // }
    }
  }

  save() {
    console.log("Emp Emp Department", this.req_employeedepgroup)
    console.log("Emp Group", this.isGroupchanges)
    let ischange = false;
    for (let index = 0; index < this.req_employeedepgroup.length; index++) {
      const element = this.req_employeedepgroup[index];
      console.log("For loop >> >> >>")
      if (element.isgroupchange) {
        console.log("ischange >> >> >>")
        ischange = true;
      }
    }

    console.log("Save Group  and Department >> >> >>")
    console.log("Emp Emp Department", this.req_employeedepgroup)
    console.log("Emp Group", this.isGroupchanges)

    if (ischange) {
      console.log("Save Group  and Department >> >> >> Inside")
      $('#secondaryModal').modal('toggle');
    } else {
      if (this.req_employeedepgroup.length !== 0) {
        this.shiftService.saveEmpGroup(this.req_employeedepgroup, "NO CHANGE").subscribe({
          next: (res) => {
            console.log(res);
            this.alertmessages("Employeee Group Allocated successfully", "success");
            this.clear()
          },
          error: (e) => {
            this.alertmessages("Error occured while saving , Please try again ", "danger");
          }
        })
      }
    }

  }

  confirmedSave(attribute: string) {
    console.log(attribute)
    if (this.req_employeedepgroup.length !== 0) {
      this.shiftService.saveEmpGroup(this.req_employeedepgroup, attribute).subscribe({
        next: (res) => {
          console.log(res);
          this.alertmessages("Employeee Group Allocated successfully ", "success");
          this.clear()
        },
        error: (e) => {
          this.alertmessages("Error occured while saving , Please try again ", "danger");
        }
      })
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  clear() {
    this.groupid = null;
    this.department = null;
    this.employeedepgroup = [];
    this.req_employeedepgroup = []
  }

  onScroll(e: EmpDepartmentGroup) {
    this.offset = this.offset + 10;
    console.log(e.department, e.group, "Onscroll")
    this.search(e);
  }

  limitpara() {
    this.limit = 0;
  }

  @HostListener('scroll', ['$event'])
  scrolled(e) {
    this.onScroll(e);
    console.log(e, "inside scrolled function")
    const ele = document.getElementById('scroll')
  }

  scrollLength() {
    console.log("dynamic height on scroll ");
    console.log("");
    const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
    const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
    const computedHeight = height.match(regex);
    console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
    if (computedHeight && Number(computedHeight[1]) < 450) {
      let value: any = Number(computedHeight[1]);
      console.log("default value = ", value)
      value = value + (2 * 55);
      value = value + "px";
      console.log("final vl:", value)
      console.log("transition")
      document.getElementById("scrolllength").style.maxHeight = value
    }
    console.log("");
  }


}
