<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">

    </div>
    <!-- <div class="page-header d-xl-flex d-block ">
      <div class="page-leftheader">
        <div class="page-title">Department Details</div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card-content">
          <div class="card">
            <div class="card-body row">
              <form id="fcForm" class="form-horizontal form-label-left" novalidate>
                <div class="x_content row">

                  <div class=" row col-md-6 col-sm-6 col-xs-6 px-2">
                    <div class="form-group label-floating px-5">
                      <label class="form-label"> Department Name </label>

                      <input type="text" [(ngModel)]="name" class="form-control col-md-12 col-xs-12"
                        placeholder="Enter Department Name" autocomplete="off" required="required" autofocus="true"
                        id="textType" />
                    </div>
                  </div>
                  <!-- </div> -->
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group label-floating justify-content-between align-items-center">
                      <label class="form-label">&nbsp;</label>
                      <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                        style="margin-right: 3vw;margin-left: 2vw;" (click)="saveDepartment()">
                        Add
                      </button>
                      <!-- <ng-template #updateButton>
                        <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                            style="margin-right: 2vw;margin-left: 2vw;" (click)="updateGroup()">
                            Update
                        </button>
                    </ng-template> -->
                      <button type="button" class="btn btn-danger w-40" type="button" (click)="reFresh()">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- (keyup.enter)="limitpara()" -->
    <!-- <div *ngIf="list!=null"> -->
    <div class="row card-list-margin">
      <div class="col-xl-12 col-md-12 col-lg-12 card-list1-margin">
        <div class="card">
          <div class="card-header  border-0">
            <h4 class="card-title">Employees List</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="col-xs-12 mobile">
                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                  [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                  (scrolled)="onScroll()">

                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <!-- <th class="border-bottom-0 fs-6">Department ID</th> -->
                        <th class="border-bottom-0 fs-7">Department Name </th>
                        <th class="border-bottom-0 fs-7">Created on </th>
                        <th class="border-bottom-0 fs-7">Created by </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of list">
                        <!-- <td data-th = "Department ID">
                                   {{item.departmentId }}
                                 </td> -->
                        <td data-th="Department name">
                          {{item.name}}
                        </td>
                        <td data-th="Time Stamp">
                          {{item.timestamp| date: 'mediumDate'}}
                        </td>
                        <td data-th="User ID">
                          {{item.userId}}
                        </td>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>