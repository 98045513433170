<div class="app-content main-content">
    <div class="side-app main-container">
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Leave Status Report</div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    (change)="onLeaveStatusChange($event.target.value)"
                                                    name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Leave Approve Pending">Leave Approve Pending</option>
                                                    <option value="Leave Cancel Pending">Leave Cancel Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <form class="form-horizontal" id="fcForm"
                                    (keyup.enter)="limitpara(); searchEmployeeLeave()">
                                    <div class="form-group row">
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <label for="employeename"
                                                class="w-100 max-content fontSize-15 form-label my-auto mb-0 ps-2"
                                                style="padding-right:35px">Employee Name / ID </label>
                                            <div class="employeeSearch col-xs-12 col-md-12 px-0">
                                                <input type="search" class="form-control " name="employeename"
                                                    id="employeename" [(ngModel)]="query" autocomplete="off"
                                                    (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                    autofocus="autofocus" placeholder="Search Employee Name / ID">
                                                <datalist id="employeeMaster">
                                                    <option data-value={{emp.name}} *ngFor="let emp of employeeMaster">
                                                        {{emp.employeeid}}
                                                    </option>
                                                </datalist>
                                            </div>
                                        </div>

                                        
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Order By</label>
                                                <div>
                                                    <select class="form-control" [(ngModel)]="orderBy"
                                                        (change)="onOrderByChange()">
                                                        <option value="employeeId">Employee ID</option>
                                                        <option value="employeeName">Employee Name</option>
                                                        <option value="leaveDate">Leave Date</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="col-md-4 col-sm-4 col-xs-4" style="margin-top: 25px;">
                                            <button type="button" id="navigateclient"
                                                class="btn btn-primary w-100 w-md-100 mt-3 mt-md-0 p-2 p-md-1"
                                                (click)="limitpara(); searchEmployeeLeave()">
                                                <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    (change)="onLeaveStatusChange($event.target.value)"
                                                    name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Leave Approve Pending">Leave Approve Pending</option>
                                                    <option value="Leave Cancel Pending">Leave Cancel Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" style="padding: 0px 0px">
                                    <form class="form-horizontal" id="fcForm"
                                        (keyup.enter)="limitpara(); searchEmployeeLeave()">
                                        <div class="form-group row">
                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                <div class="form-group label-floating">
                                                    <label for="employeename" class="form-label">Employee Name / ID
                                                    </label>
                                                    <div class="employeeSearch col-xs-12 col-md-12 px-0">
                                                        <div>
                                                            <input type="search" class="form-control"
                                                                name="employeename" id="employeename"
                                                                [(ngModel)]="query" autocomplete="off"
                                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                                autofocus="autofocus"
                                                                placeholder="Search Employee Name / ID">
                                                            <datalist id="employeeMaster">
                                                                <option data-value={{emp.name}}
                                                                    *ngFor="let emp of employeeMaster">
                                                                    {{emp.employeeid}}
                                                                </option>
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Order By -->
                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                <div class="form-group label-floating">
                                                    <label class="form-label">Order By</label>
                                                    <div>
                                                        <select class="form-control" [(ngModel)]="orderBy"
                                                            (change)="onOrderByChange()">
                                                            <option value="employeeId">Employee ID</option>
                                                            <option value="employeeName">Employee Name</option>
                                                            <option value="leaveDate">Leave Date</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Search Button -->
                                            <div class="col-md-4 col-sm-4 col-xs-4 mt-5">
                                                <button type="button" id="navigateclient" class="btn btn-primary w-100"
                                                    (click)="limitpara(); searchEmployeeLeave()">
                                                    <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="page-header d-xl-flex d-block" *ngIf="showTotalLeaveReport">
            <div class="page-leftheader">
                <div class="page-titleMod">Total Leave Report</div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="card-body"> -->
                <div class="card" *ngIf="showTotalLeaveReport">
                    <div class="card-body">
                        <div class="card-header  border-0">
                            <h4 class="card-title">Total Leave Report</h4>
                        </div>

                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="row mt-3">
                               <div class="panel-body border-0 d-flex flex-wrap">
                                    <div *ngFor="let leaveTypeCount of leaveTypeCounts" class="p-1 d-flex font-weight-semibold align align-items-center">
                                        <span [style.backgroundColor]="colorCodeMap.get(leaveTypeCount.leaveName)" class="dot-label me-2"></span>
                                        {{ leaveTypeCount.leaveName }}: {{ leaveTypeCount.count }}
                                    </div>
                                </div>
                                <div class="col-md-3 mx-auto text-center">
                                    <label>Total Absence: {{ totalAbsence }}</label>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>
            <!-- </div>
        </div> -->

        <div class="empty-div-one" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">{{title}}</h4>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div *ngIf="employeesLeaveSummary !== null ">
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                <th class="border-bottom-0">Leave Type</th>
                                                <th class="border-bottom-0">Leave Date</th>
                                                <th class="border-bottom-0">Day</th>
                                                <th class="border-bottom-0">Reason</th>
                                                <th class="border-bottom-0">Applied on</th>
                                                <th class="border-bottom-0">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let leave of employeesLeaveSummary">
                                                <td class="fixedcolumn backgroundFW">{{leave.employeeId}}</td>
                                                <td class="secondcolumn backgroundSW">
                                                    <div class="d-flex">
                                                        <div class="me-3 mt-0 mt-sm-2 d-block">
                                                            <h6 class="mb-1 fs-14">{{leave.employeeName}}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span _ngcontent-fts-c87="" class="badge "
                                                        style="font-size: 13px;font-weight: 500; color: black;"
                                                        [style.background-color]="leave.colorCode">{{leave.leaveName}}</span>
                                                </td>

                                                <td>{{leave.leaveDate| date: 'mediumDate'}}</td>
                                                <td class="font-weight-semibold">{{leave.halfFullIndic === "F" ?
                                                    "1" : "0.5"}}</td>
                                                <td>{{leave.reason}}</td>
                                                <td>{{leave.appliedDate| date: 'mediumDate'}}</td>
                                                <td>
                                                    <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="leave.leaveStatus === 'Approved' ? 'badge-success' : leave.leaveStatus === 'Rejected' ? 'badge-danger' : leave.leaveStatus === 'Leave Cancel Rejected' ? 'badge-danger':leave.leaveStatus === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{leave.leaveStatus}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>