import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Employeeshiftsallocated } from '../models/Employeeshiftsallocated';
import { Groupinfo } from '../models/Groupinfo';
import { EmployeeShiftService } from '../services/employee-shift.service';
declare var $:any;
 
interface groupShiftinfo{
  group:number;
  shift:number;
  fromdate:string;
  todate:string;
  minid:number;
  maxid:number;
 }

@Component({
  selector: 'app-shiftallocation',
  templateUrl: './shiftallocation.component.html',
  styleUrls: ['./shiftallocation.component.css']
})
export class ShiftallocationComponent implements OnInit {
  group: Groupinfo;
  groupid:number=0;
  shift:number=0;
  fromdate:string;
  todate:string;

  shifts:any[];

  isupdate:boolean;

  allocatedShits:Employeeshiftsallocated[] = [];

  req_shiftTobeupdated:groupShiftinfo;


  defaultsearchby:string ;


  constructor(private shiftService:EmployeeShiftService , private datepipe:DatePipe,
    private router: Router) { 
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; 
      this.fromdate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      this.todate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    }

  ngOnInit(): void {
    this.defaultsearchby = "date"
    this.getgroups()
    this.getshifts()
    this.getallocatedShifts(this.defaultsearchby)
  }
  getgroups(){
    this.shiftService.getgroup().subscribe({
      next:(res)=>{
        this.group=res.groups
      },
      error:(e)=>{
        console.log("error occured while getting all the groups ")
      }
    })
  }

  savegroup_ToShiftdata(groupid:number , shiftid:number ,fromdate:string , todate:string){
  console.log("🚀 ~ file: shiftallocation.component.ts ~ line 40 ~ ShiftallocationComponent ~ savegroup_ToShiftdata ~ todate", todate)
  console.log("🚀 ~ file: shiftallocation.component.ts ~ line 40 ~ ShiftallocationComponent ~ savegroup_ToShiftdata ~ fromdate", fromdate)
  console.log("🚀 ~ file: shiftallocation.component.ts ~ line 40 ~ ShiftallocationComponent ~ savegroup_ToShiftdata ~ shiftid", shiftid)
  console.log("🚀 ~ file: shiftallocation.component.ts ~ line 40 ~ ShiftallocationComponent ~ savegroup_ToShiftdata ~ groupid", groupid)
  

    console.log(groupid,shiftid,todate,fromdate,"ds")
    if(groupid === undefined || groupid.toString()==="null" || groupid==0){
      this.alertmessages("Please select a group","danger")
      $('#shiftModal').modal('toggle');
      return;
    }
    if(shiftid === undefined ||  shiftid.toString()==="null" || shiftid==0){
      this.alertmessages("Please select a shift","danger")
      $('#shiftModal').modal('toggle');
      return;
    }
    if(!todate || todate.length === 0  ){
      this.alertmessages("To date Mandatory" , "danger")
      $('#shiftModal').modal('toggle');
      return;
    }
    if( !fromdate && fromdate.length === 0){
      this.alertmessages("From date Mandatory" , "danger")
      $('#shiftModal').modal('toggle');
      return;
    }

    if(!this.validateaDate(fromdate)){
      this.alertmessages("Date a not valid" , "danger")
      $('#shiftModal').modal('toggle');
      return;
    }else if(!this.validateaDate(todate)){
      this.alertmessages("Date is not valid" ,"danger")
      $('#shiftModal').modal('toggle');
      return;
    }


    let isValid:boolean = this.checkfromisGreaterThantodate(fromdate , todate);
    if(isValid){
      this.alertmessages("From date greater than To date" , "danger")
      $('#shiftModal').modal('toggle');
      return;
    }
    
      this.shiftService.savegroupToShiftdata(groupid, shiftid,fromdate , todate)
      .subscribe({
        next:(res)=>{
          console.log(res)
          this.alertmessages("Employee shift Allocated successfully",'success')
          this.getallocatedShifts(this.defaultsearchby)
          $('#shiftModal').modal('toggle');
        },
        error:(err)=>{
          // console.log(err.errorMessages)
          if(err.error.errorMessages[0]['key']){
            this.alertmessages(err.error.errorMessages[0]['key'],"danger");
            $('#shiftModal').modal('toggle');
          }else{
            $('#shiftModal').modal('toggle');
          }
        }
      })

  }

  checkfromisGreaterThantodate(from , to):boolean{
    let fromdate:Date = new Date(from);
    let todate:Date = new Date(to);

    if(fromdate.valueOf() > todate.valueOf()){
      return true 
    }
    return false;  
  }

  validateaDate(d):boolean{
    let date:Date = new Date(d);
    let lyear = 1900;
    let currentyear = date.getFullYear();
    if(currentyear > lyear){
      return true;
    }
    return false;

  }
showSavemodal(){
  $('#shiftModal').modal('toggle');
}

  save(e:groupShiftinfo){
    console.log(e)
    this.savegroup_ToShiftdata(e.group,e.shift,e.fromdate,e.todate);
  }

  update(e){
    console.log(e)
    this.updategroup_ToShiftdata(e.group,e.shift,e.fromdate,
      e.todate,this.req_shiftTobeupdated.minid ,this.req_shiftTobeupdated.maxid);
  }
  updategroup_ToShiftdata(groupid:number , shiftid:number ,fromdate:string , todate:string, minid: number, maxid: number) {
    if(groupid === undefined || groupid.toString()==="null" ){
      this.alertmessages("Please select a group","danger")
      return;
    }
    if(shiftid === undefined || shiftid.toString()==="null"){
      this.alertmessages("Please select a shift","danger")
      return;
    }
    if(!todate || todate.length === 0  ){
      this.alertmessages("To date Mandatory" , "danger")
      return;
    }
    if( !fromdate && fromdate.length === 0){
      this.alertmessages("From date Mandatory" , "danger")
      return;
    }

    if(!this.validateaDate(fromdate)){
      this.alertmessages("Date a not valid" , "danger")
      return;
    }else if(!this.validateaDate(todate)){
      this.alertmessages("Date is not valid" ,"danger")
      return;
    }


    let isValid:boolean = this.checkfromisGreaterThantodate(fromdate , todate);
    if(isValid){
      this.alertmessages("From date greater than To date" , "danger")
      return;
    }

    if(this.isupdate){
      // call update api
    }
  }
  
  getshifts(){
    this.shiftService.getshifts()
      .subscribe({
        next:(res)=>{
          console.log(res);
          this.shifts = res.shiftMasterdetail
        },
        error:(err)=>{
          console.log(err)
        }
      })
  }

  getallocatedShifts(searchby:string){
    this.shiftService.getallocatedShifts(searchby).subscribe({
      next:(res)=>{
        this.allocatedShits = res.allocatedshits;
        console.log(res)
      },error:(err)=>{
        console.log(err)
      }
    })
  }

  alertmessages(message:string , sign:string):void{
		let alertDiv = document.createElement('div');         
		 alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;
    
		setTimeout(()=>{
		  alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/group-shiftallocation']);
    });
		}, 3000);
	}


  editAllocatedShifts(info:Employeeshiftsallocated){
    console.log(info)
    // let saveBtn = document.getElementById('btnsave');
    // saveBtn.innerText = "Update"
    // saveBtn.classList.remove('btn-success');
    // saveBtn.classList.add('btn-warning');
    this.isupdate = true;
    this.groupid = info.empgroupid;
    this.shift = info.assignedshiftId
    this.fromdate = this.datepipe.transform( new Date(info.fromdate) , 'yyyy-MM-dd')
    this.todate = this.datepipe.transform( new Date(info.todate) , 'yyyy-MM-dd')

    this.req_shiftTobeupdated.maxid = info.maxid
    this.req_shiftTobeupdated.minid = info.minid 
  }
  clear(){
    this.isupdate = false;
    this.groupid = null;
    this.shift = null;
    this.fromdate = null;
    this.todate = null;
    this.req_shiftTobeupdated = null;
  }

  closeMRQModal() {
    $('#shiftModal').modal('toggle');
  }
  confirmsave(e:groupShiftinfo){
    this.save(e);
  }

  searchby(e){
    console.log(e.target.value)
    if(e.target.value !=="null"){
      this.defaultsearchby = e.target.value
      this.getallocatedShifts(this.defaultsearchby)
    }
  }
}
