import { Component, OnInit } from '@angular/core';
import { TDSReportService } from '../services/TDSreport.service'
import { DatePipe } from '@angular/common';
import { TDSStatementReportInfo } from '../models/tds-report-info.models';

declare var $: any;

@Component({
  selector: 'app-tds-report',
  templateUrl: './tds-statement-report.component.html',
  styleUrls: ['./tds-statement-report.component.css']
})

export class TDSSTATEMENTCOMPONENT implements OnInit {
  limitPara: any; offsetPara: any;
  currentDate: string; fromDate: string;
  startDate: boolean; endDate: boolean;
  submitted: boolean; toDate: string;
  tdsStmtInfo: TDSStatementReportInfo[];

  constructor(private tdsReportservice: TDSReportService, private datepipe: DatePipe) {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }
  ngOnInit(): void {
    this.limitPara = 17;
    this.offsetPara = 0;
  }
  onScroll() {
    this.limitPara = this.limitPara + 5;
    this.offsetPara = this.offsetPara + 13;
  }

  clear() {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.tdsStmtInfo = null;
  }
  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();
    console.log("this.fromDate", this.fromDate);
    console.log("this.toDate ", this.toDate);
    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  search() {
    this.tdsReportservice.getTDSRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd')).subscribe(
      (data) => {
        // debugger;
        this.tdsStmtInfo = data.tdsStmtInfo;
        console.log("this.tdsStmtInfo" + this.tdsStmtInfo);

        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },

      (error) => {
        console.log(error)
      }
    );
  }
  TDSreportCSVDownload() {
    this.tdsReportservice.downloadTDSReport(this.fromDate, this.toDate).subscribe(data => {
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "TDSStatementReport.xls";
      anchor.href = fileURL;
      anchor.click();
      console.log("anchor.download" + anchor.download);
      console.log("data" + data);
      console.log("fileURL" + fileURL);
    },
      error => {
        console.log("Error Occured ", error);
      })
  }
}