<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp"></div>

        <!-- <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title">Freeze Allocation</div>
        </div> -->

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">

                <div class="card-body row justify-content-between">
                    <div class="col-xs-11 col-md-11 col-sm-10 col-10">

                        <div class="my-3">

                           
                            <select id="employeeMasterLucene" class="form-control" name="Employee Name" id="employeename"
                            placeholder="Search Business Name" autocomplete="off" list="employeeMasterLucene"
                            autofocus="autofocus" [(ngModel)]="contractClientProName"
                            (ngModelChange)="getclientLocDetail($event,contractClientProName)">
                                <option data-value={{emp.business_name}} *ngFor="let emp of contractClientData">
                                    {{emp.business_name}}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div class="col-xs-1 col-md-1 col-sm-2 col-2 my-3">
                        <button type="button" class="btn btn-primary w-100" (click)="getProjectInfo()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;" *ngIf="showprojectList">
            <div class="card">

                <form (ngSubmit)="contractClientFreezForm.form.valid" name="addContractClientFreezForm"
                    #contractClientFreezForm="ngForm" novalidate>
                    <section>

                        <div class="card-body row py-5">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pe-4 ps-4">
                                <div class="form-group label-floating">
                                    <label class="form-label" id="formLable">
                                        Projects
                                    </label>
                                    <div *ngIf="contractClientProInfoData.length!=0">
                                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dGroup"
                                            name="projectname" #projectname="ngModel"
                                            [(ngModel)]="contractClientProFreezeData.projectname"
                                            (ngModelChange)="setFromAndToDate($event)" required>

                                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                Select Project</option>
                                            <option [ngValue]="data" *ngFor="let data of contractClientProInfoData">
                                                {{data.projectname}}
                                            </option>

                                        </select>

                                        <p *ngIf="submitted && !projectname.valid" class="help-block "
                                            style="color: #ff5757;">
                                            ⓘ Project name is required
                                        </p>
                                    </div>
                                </div>
                            </div>



                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex pe-4 ps-4"
                                *ngIf="startDateForProject">
                                <!-- <p class="form-label text-center my-2">Project start on {{startDateForProject |
                                    date:'MM/dd/yyyy'}}</p>
                                <p class="form-label text-center my-2">Project end on {{endDateForProject |
                                    date:'MM/dd/yyyy'}}</p> -->

                                <p class="form-label text-center mt-6">Project start on {{startDateForProject |
                                    date:'MM/dd/yyyy'}} and end on {{endDateForProject |
                                    date:'MM/dd/yyyy'}}</p>

                                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                    *ngIf="startDateForProject">
                                    <div class="form-group">
                                        <p class="form-label text-center">Project start on {{startDateForProject |
                                            date:'MM/dd/yyyy'}}</p>
                                    </div>
                                </div>
    
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="endDateForProject">
                                    <div class="form-group">
                                        <p class="form-label text-center">Project end on {{endDateForProject |
                                            date:'MM/dd/yyyy'}}</p>
                                    </div>
                                </div> -->
                            </div>

                            <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pe-4 ps-4 "
                                *ngIf="contractClientProInfoData.length!=0">
                                <div class="form-group" *ngIf="startDateForProject">
                                    <button class="btn btn-primary w-100" type="button"
                                        (click)="addProjectFreezeData(contractClientFreezForm.form.valid)">Freeze till ~
                                        <span>{{freezeTillDate | date:'MM/dd/yyyy'}}</span></button>
                                </div>
                            </div> -->

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pe-4 ps-4"
                                *ngIf="contractClientProInfoData.length!=0">
                                <div class="form-group" *ngIf="startDateForProject">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Freeze till</label>
                                        <div>
                                            <input type="date" class="form-control w-100" id="freeze_date"
                                                name="freeze_date" #freeze_date="ngModel"
                                                [(ngModel)]="contractClientProFreezeData.freeze_date"
                                                (ngModelChange)="setFreezeDate($event)">
                                        </div>
                                    </div>
                                </div>

                                <div class="actionBar pe-4 ps-4" *ngIf="startDateForProject">
                                    <div class="clearfix"> </div>
                                    <div>
                                        <button class="btn btn-success" type="button"
                                            (click)="addProjectFreezeData(contractClientFreezForm.form.valid)">Freeze</button>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>
                        </div>
                    </section>
                </form>
            </div>
        </div>

        <div class="row {{contractClientProFreezeInfoData.length!=0 ? '' : 'disable-div'}}">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header border-0 py-3 d-flex justify-content-between" id="showTable">
                        <h4 class="card-title"><span *ngIf="!showfreezeList">View </span>Freezed Projects</h4>
                        <button class="btn btn-primary btn-icon btn-sm" (click)="showFreezeTable()" title="Show">
                            <i class="fa fa-th-list" id="showFreezeProjectDataList"></i>
                        </button>
                    </div>
                    <div class="card-body" *ngIf="showfreezeList">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Project Name</th>
                                                <th class="border-bottom-0 w-5 fs-6">Freeze Till</th>
                                                <th class="border-bottom-0 w-5 fs-6">Added On</th>
                                                <th class="border-bottom-0 w-5 fs-6">Added By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of contractClientProFreezeInfoData">
                                                <td data-th="contractclientName">{{data.projectname}}
                                                </td>
                                                <td data-th="contractclientName">{{data.freeze_date |
                                                    date:'MM/dd/yyyy'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.createdate| date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.createuser}}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantFreeze" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <p class="mg-b-20 mg-x-20">{{projectFreezeDate}}</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantFreezeX" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <!-- <p class="mg-b-20 mg-x-20">{{projectFreezeDate}}</p> -->

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>