<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Apply Attendance for Others</div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <div class="card-body">

                        <form id="employeeForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Date</label>
                                        <div>
                                            <input class="form-control" type="date" (input)="selectDate()"
                                                id="searchDate" [disabled]="!showBasedOnPermissionDate" />

                                        </div>
                                    </div>
                                </div>


                                <!-- *ngIf="showBasedOnPermissionBranch" -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Branch</label>
                                        <div>

                                            <select id="deptInput" class="form-control" name="branchid"
                                                placeholder="Select Department" [(ngModel)]="branchid"
                                                autocomplete="off" autofocus="autofocus"
                                                (ngModelChange)="getSelectedBranch($event)"
                                                [disabled]="!showBasedOnPermissionBranch">

                                                <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                    {{data.branchname}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </div>

                                <!-- *ngIf="showBasedOnPermissionDepartment" -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Department</label>
                                        <div>

                                            <select id="deptInput" class="form-control" name="departmentid"
                                                placeholder="Select Department" [(ngModel)]="departmentid"
                                                autocomplete="off" autofocus="autofocus"
                                                (ngModelChange)="getSelectedDept($event)"
                                                [disabled]="!showBasedOnPermissionDepartment">

                                                <option value=0 selected="selected">All</option>
                                                <option value={{data.departmentId}} *ngFor="let data of departmentInfo">
                                                    {{data.departmentname}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </div>

                                <!-- adding drop down option .. for filter  -->


                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Employee</label>
                                        <div>
                                            <select id="activeExitFilters" class="form-control" name="dateFilter"
                                                placeholder="Select Employee Type" [(ngModel)]="activeExitFilters"
                                                (ngModelChange)="getEmployeeType($event)">
                                                <option value="Active" [selected]="true">Active Employee</option>
                                                <option value="Exited">Exited Employee</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="actionBar pt-0 m-0">
                                    <div class="clearfix"> </div>
                                    <div>
                                        <button class="btn btn-primary btn-icon btn-sm me-2" style="width: 32px;"
                                            data-bs-placement="top" (click)="matrixPopup(); triggerChild()">
                                            <i class="fa fa-plus-square" data-bs-toggle="tooltip"
                                                data-original-title="penaltyremark"></i>
                                        </button>

                                    </div>
                                </div> -->

                                <!-- pe-4 ps-4 -->
                                <div class="actionBar pt-0 m-0">
                                    <div class="clearfix"> </div>
                                    <div>

                                        <!-- <a href="#" (click)="openAttendanceReport($event)">View Attendance Report</a> -->
                                        <!-- <button class="btn btn-primary  me-2" data-bs-placement="top" (click)="matrixPopup(); triggerChild()">Report
                                        </button> -->

                                        <button type="button" id="navigatetarget"class="btn btn-primary m-2  w-20  p-md-1 p8"
                                        (click)="getEmpDailyDetails(activeExitFilters)"><i class="fa fa-search" aria-hidden="true"></i> Search
                                        </button>
                                        <!-- <button class="btn btn-primary m-2" (click)="EnableLocation()">Enable Location</button> -->
                                        <!-- <button class="btn btn-success m-2" (click)="saveEmpWorkDetails()">Save</button>
                                        <button class="btn btn-danger m-2" (click)="refresh()">Clear</button> -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" style="height: 1px;" id="navigatedest1"></div>

        <!-- *ngIf="this.empInfo.length!=0" -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">

                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">Attendance</h4>
                        <a href="#" (click)="matrixPopup(); triggerChild()">View Attendance Report</a>
                    </div>

                    <!-- <div class="car    d-body">
                        <div class="col-xs-12 mobile view">
                            <div class="scrollsearchresults" id="scrolllength" infiniteScroll
                                [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                [infiniteScrollThrottle]="100" [scrollWindow]="false" (scrolled)="onScroll()"
                                id="scrolllength">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow stickyatTop">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0 fs-7">Employee</th>
                                            <th class="border-bottom-0 fs-7">Department</th>
                                            <th class="border-bottom-0 fs-7">session One<br /><span
                                                    style="font-size: 10px; top: -5px">{{ amIn | stringDate24To12 }} -
                                                    {{amOut | stringDate24To12}}</span></th>
                                            <th class="border-bottom-0 fs-7">session Two<br /><span
                                                    style="font-size: 10px; top: -5px">{{ pmIn | stringDate24To12 }} -
                                                    {{pmOut | stringDate24To12}}</span></th>
                                            <th class="border-bottom-0 fs-7">session Three<br /><span
                                                    style="font-size: 10px; top: -5px">{{ nsIn | stringDate24To12 }} -
                                                    {{nsOut | stringDate24To12}}</span></th>
                                            <th class="border-bottom-0 fs-7">Overtime</th>
                                            <th class="border-bottom-0 fs-7">OT Type</th>
                                        </tr>
                                    </thead>

                                    <tbody infiniteScroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="3000"
                                        [scrollWindow]="false" (scrolled)="onScroll()" id="navigatetarget">
                                        <tr *ngFor="let j=index;  let ew of empInfo">
                                            <td data-th="Employee">

                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    {{ew.name}}</div>

                                            </td>

                                            <td data-th="Department">

                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    {{ew.departmentname}}</div>

                                            </td>

                                            <td data-th="session One">

                                               
                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    
                                                    <input type="checkbox" id="session1"
                                                        [checked]="ew.morning===true || ew.morning ==='True' "
                                                        (change)="checkThebox(ew,j,$event,'morning')" class="flat">

                                                    <label class="pl-2 m-0 mx-2 session_date" for="session1" ><span style="font-size: 10px; top: -5px">{{
                                                            amIn |
                                                            stringDate24To12 }}<br />{{amOut |
                                                            stringDate24To12}}</span></label>

                                                </div>
                                            </td>
                                            <td data-th="session Two">

                                               
                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    <input type="checkbox" id="session2"
                                                        [checked]="ew.afternoon===true || ew.afternoon ==='True' "
                                                        (change)="checkThebox(ew,j,$event,'afternoon')" class="flat">
                                                    <label class="pl-2 m-0 mx-2 session_date" for="session2"><span style="font-size: 10px; top: -5px">{{
                                                            pmIn | stringDate24To12
                                                            }}<br />{{pmOut |
                                                            stringDate24To12}}</span></label>



                                                </div>
                                            </td>
                                            <td data-th="session Three">


                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    <input type="checkbox" id="session3"
                                                        [checked]="ew.night===true || ew.night ==='True' "
                                                        (change)="checkThebox(ew,j,$event,'sessionThree')" class="flat">

                                                    <label class="pl-2 m-0 mx-2 session_date" for="session3"><span style="font-size: 10px; top: -5px">{{
                                                            nsIn | stringDate24To12 }}<br />{{nsOut |
                                                            stringDate24To12}}</span></label>



                                                </div>
                                            </td>


                                            <td data-th="OT">

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <input type="text" [(ngModel)]="ew.ot" maxlength="3"
                                                        class="form-control w-100" placeholder="Enter OT ">
                                                </div>

                                            </td>
                                            <td data-th="OT Type">

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <select [(ngModel)]="ew.ottype" class="form-control w-100">
                                                        <option [selected]="otTime==='Hours'" [value]="otTime"
                                                            *ngFor="let otTime of OTarray">
                                                            {{otTime}}</option>
                                                    </select>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div class="scrollsearchresults" id="scrolllength" infiniteScroll
                                [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                [infiniteScrollThrottle]="100" [scrollWindow]="false" (scrolled)="onScroll()"
                                id="scrolllength">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow stickyatTop">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0 fs-7">Employee</th>
                                            <th class="border-bottom-0 fs-7">Department</th>
                                            <th class="border-bottom-0 fs-7">session One<br /><span
                                                    style="font-size: 10px; top: -5px">{{ amIn |
                                                    stringDate24To12RoundFigureTime }} -
                                                    {{amOut | stringDate24To12RoundFigureTime}}</span></th>
                                            <th class="border-bottom-0 fs-7">session Two<br /><span
                                                    style="font-size: 10px; top: -5px">{{ pmIn |
                                                    stringDate24To12RoundFigureTime }} -
                                                    {{pmOut | stringDate24To12RoundFigureTime}}</span></th>
                                            <th class="border-bottom-0 fs-7">session Three<br /><span
                                                    style="font-size: 10px; top: -5px">{{ nsIn |
                                                    stringDate24To12RoundFigureTime }} -
                                                    {{nsOut | stringDate24To12RoundFigureTime}}</span></th>
                                            <th class="border-bottom-0 fs-7">Overtime</th>
                                            <th class="border-bottom-0 fs-7">OT Type</th>
                                            <th class="penalty"></th>
                                        </tr>
                                    </thead>

                                    <tbody infiniteScroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="3000"
                                        [scrollWindow]="false" (scrolled)="onScroll()" id="navigatetarget">
                                        <tr *ngFor=" let index = index;  let ew of empInfo" class="tr_table">
                                            <td data-th="Employee" class="td_employee">

                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    {{ew.name}}<br />
                                                    <!-- <span style="font-size: 10px; top: -5px" 
                                                    class="session_date">{{ew.departmentname}}</span> -->
                                                </div>

                                            </td>

                                            <td data-th="Department" class="td_department">

                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    {{ew.departmentname}}</div>

                                            </td>

                                            <td data-th="Session One" class="td_session_one td_session">
                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    <label class="custom-switch">A</label>
                                                    <label class="custom-switch">
                                                        <input type="checkbox" name="custom-switch-checkbox"
                                                            class="custom-switch-input" [(ngModel)]="ew.morning"
                                                            (change)="checkThebox(ew, index, $event, 'morning')">
                                                        <span class="custom-switch-indicator"></span>
                                                    </label>
                                                    <label class="custom-switch">P</label>
                                                </div>
                                            </td>

                                            <td data-th="Session Two" class="td_session_one td_session">
                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    <label class="custom-switch">A</label>
                                                    <label class="custom-switch">
                                                        <input type="checkbox" name="custom-switch-checkbox"
                                                            class="custom-switch-input" [(ngModel)]="ew.afternoon"
                                                            (change)="checkThebox(ew, index, $event, 'afternoon')">
                                                        <span class="custom-switch-indicator"></span>
                                                    </label>
                                                    <label class="custom-switch">P</label>
                                                </div>
                                            </td>

                                            <td data-th="Session Three" class="td_session_one td_session">
                                                <div class="col-md-8 col-sm-12 col-xs-12">
                                                    <label class="custom-switch">A</label>
                                                    <label class="custom-switch">
                                                        <input type="checkbox" name="custom-switch-checkbox"
                                                            class="custom-switch-input" [(ngModel)]="ew.night"
                                                            (change)="checkThebox(ew, index, $event, 'night')">
                                                        <span class="custom-switch-indicator"></span>
                                                    </label>
                                                    <label class="custom-switch">P</label>
                                                </div>
                                            </td>
                                            <td data-th="OT" class="td_session">

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <input type="text" [(ngModel)]="ew.ot" maxlength="3"
                                                        class="form-control w-100" placeholder="Enter OT ">
                                                </div>

                                            </td>
                                            <td data-th="OT Type" class="td_session">

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <select [(ngModel)]="ew.ottype" class="form-control w-100">
                                                        <option [selected]="otTime==='Hours'" [value]="otTime"
                                                            *ngFor="let otTime of OTarray">
                                                            {{otTime}}</option>
                                                    </select>
                                                </div>
                                            </td>

                                            <td data-th="Action" class="td_session">
                                                <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    style="width: 32px;" data-bs-placement="top"
                                                    (click)="penaltyPopup(ew)" [disabled]="!ew.enablepenalty">
                                                    <i class="fa fa-plus-square" data-bs-toggle="tooltip"
                                                        data-original-title="penaltyremark"></i>
                                                </button>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" style="height: 30px;" id="navigatedest"></div>

        <div class="actionBar">
            <div class="clearfix"> </div>
            <div class="pro-fab">
                <!-- <button class="btn btn-primary m-2" (click)="getEmpDailyDetails()">Search</button> -->
                <button class="btn btn-success m-2" (click)="saveEmpWorkDetails()">Save</button>
                <!-- <button class="btn btn-danger m-2" (click)="refresh()">Clear</button> -->
            </div>
        </div>
    </div>
</div>



<!--modal -->
<div class="modal fade" id="plusModal1">
    <div class="modal-dialog modal-lg " role="document">
        <div class="modal-content">
            <form class="form-horizontal" #penalty="ngForm" (ngSubmit)="saveData(penalty)">
                <div class="modal-header">

                    <button class="btn-close_2" (click)="closemodal($event)" style="height:30px" aria-label="Close">
                        <span aria-hidden="true">X</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="alertcomp1"></div>
                    <div class="form-group">
                        <div class="form-check">
                            <label class="form-check-label" for="penaltyCheckbox"
                                style="margin-left: 2px">Penalty</label>
                            <input class="form-check-input" id="penaltyCheckbox" type="checkbox"
                                [(ngModel)]="selectedEmpObjforPenalty.penalty" name="penalty" #penControl="ngModel"
                                (change)="toggleRemarksTextarea()">

                        </div>

                        <!-- <div class="d-flex align-items-center"> -->

                        <label class="ml-2" for="remarksTextarea">Remarks</label>
                        <div class="d-flex align-items-center">
                            <textarea class="form-control ml-2" id="remarksTextarea" rows="3"
                                [(ngModel)]="selectedEmpObjforPenalty.remarks" name="remarks"
                                [ngClass]="penControl.value && !remarkControl.valid ?'is-invalid':''"
                                [disabled]="!selectedEmpObjforPenalty.penalty" #remarkControl="ngModel"
                                [required]="penControl.value"></textarea>
                        </div>
                    </div>




                </div>
                <div class="modal-footer">
                    <button class="btn btn-success" type="submit"
                        [disabled]="penalty.invalid || (penaltyCheckbox && !remarks)">Save</button>

                    <button class="btn btn-secondary" (click)="closemodal($event)">Close</button>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade" id="plusModal2">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <!-- <form class="form-horizontal"> -->
            <div class="modal-header">

                <button class="btn-close" (click)="closemodal1($event)" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </button>
            </div>
            <div class="modal-body">
                <app-attendance-report [hide]="'true'" [startOfMonth]="startOfMonth"
                    [endOfMonth]="endOfMonth"></app-attendance-report>
            </div>
            <div id="navigatedest" #navigatedest></div>
            <div class="modal-footer">
                <button class="btn btn-secondary" (click)="closemodal1($event)">Close</button>
            </div>
            <!-- </form> -->
        </div>
    </div>
</div>