<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="container-fluid p-0 dashboard">
      <div class="row">
        <div class="card mt-0 dashboard-carousel mb-0">
          <h4 class="card-title mt-2" style="margin-bottom: 10px;">Notice Board</h4>
          <a class="carousel-control-prev" role="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" role="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
          <div id="carouselExampleInterval" class="carousel slide" data-bs-interval="5000">
            <div class="carousel-inner">
              <div class="expanel expanel-default carousel-item" [ngClass]="i === 0 ? 'active' : ''"
                *ngFor="let notice of noticeboardlist_based_on_currentdate; let i = index">
                <div class="expanel-heading">
                  <h4 class="expanel-title">{{notice.title}}</h4>

                  <a class="carousel-control-next" style="width: 50px;">
                    {{ i +1}} {{slash}} {{noticeListSize}}
                  </a>
                </div>
                <div class="expanel-body costumcolor">
                  {{notice.description}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 1rem;">
        <div class="col-md-7 col-lg-7 col-xl-8 col-sm-12 col-xs-12 p-0 " style="padding-right: 0; ">
          <div class="col-md-12 p-0">
            <div class="card mb-3 ">
              <div class="card-content padd-15 py-2">
                <div
                  class="col-md-12 d-md-flex flex-sm-column flex-xs-column flex-md-row justify-content-center align-items-center ps-0">
                  <p class="card-text col-md-6  fs-6 lh-base  d-md-flex  "
                    style="font-weight: 500; margin: 0; padding-left: 0; padding-right: 0;">
                    Manage your HR related things such as Attendance, Leave, Payslips,
                    Documents and many more, all from a single dashboard.
                  </p>
                  <div class="col-md-6 text-center" style="padding: 0; height: 140px; overflow: hidden;">
                    <img src="../../assets/img/welcome-PhotoRoom.png" style=" height: inherit;" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Frequently Used Apps-->
          <!-- <div class="col-md-12 p-0">
            <frequentlyUsedApps></frequentlyUsedApps>
          </div> -->


          <!--HIGHLIGHTS-->

          <!-- <div class="col-md-12 p-0" *ngIf="noOfemployeeabsent===0">
            <highlights></highlights>
          </div> -->

          <!--  -->
          <div class="col-md-12 p-0" *ngIf="noOfemployeeabsent!==0">
            <div class="card ">
              <div class="card-content padd-15">
                <h4 class="card-title mb-3 fontSize-20">A Few highlights of my Team</h4>
                <div class="row">
                  <div class="col-12 mb-3 mb-md-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <div class="app p-2 text-center" id="appInform">
                      <p class="h3">{{noOfemployeeabsent}}</p>
                      <h6 class="mx-auto max-w">Employees not working on {{currentdate| date: 'mediumDate'}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <app-show-all-leave-for-approval class="col-lg-6 p-0 ps-0 mobileP0" [ngClass]="{'p-0 pe-2 mobileP0': teamMemberOnLeave.length==0,
            'p-0 pe-2 mobileP0': teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0 }"
              *ngIf="leaveDetailFlow.length"
              [showLeaveForApproval]="leaveDetailFlow"></app-show-all-leave-for-approval>

            <app-team-member-on-leave class="col-lg-6 p-0 mobileP0" [ngClass]="{'p-0 ps-0 pe-2 mobileP0': leaveDetailFlow.length==0,
            'p-0 ps-2 mobileP0': teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0 }"
              *ngIf="teamMemberOnLeave.length" [teamMemberOnLeave]="teamMemberOnLeave"></app-team-member-on-leave>

            <daily-tasks class="col-lg-6 pe-0 mobileP0"
              [ngClass]="{'ps-2 mobileP0': (teamMemberOnLeave.length==0 && leaveDetailFlow.length!=0) || (leaveDetailFlow.length==0 && teamMemberOnLeave.length!=0 ),
            'p-0 pe-2 mobileP0': (leaveDetailFlow.length==0 && teamMemberOnLeave.length==0) || (teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0) }"></daily-tasks>

            <annualholiday class="col-lg-6 ps-0 mobileP0"
              [ngClass]="{'pe-2 mobileP0': (teamMemberOnLeave.length==0 && leaveDetailFlow.length!=0) || (leaveDetailFlow.length==0 && teamMemberOnLeave.length!=0 ),
            'p-0 ps-2 mobileP0': (leaveDetailFlow.length==0 && teamMemberOnLeave.length==0) || (teamMemberOnLeave.length!=0 && leaveDetailFlow.length!=0) }"
              [annualhoildaylist]="annualhoildaylist"></annualholiday>

          </div>


          <!-- <div class="col-md-12 p-0">
            <div>
              <app-employeedocdetails></app-employeedocdetails>
            </div>

          </div> -->
          <!-- <div class="col-md-12 p-0">
          </div> -->

        </div>

        <!--calender section-->
        <div class="col-md-5 col-lg-5 col-xl-4 col-sm-12 col-xs-12" style="padding-left: 0; padding-right: 0;">
          <div class="col-md-12 pr-0 mobileP0">
            <calender [annualhoildaylist]="annualhoildaylist"></calender>
          </div>

          <div class="col-md-12 pr-0 mobileP0">
            <rulesAndPolicy></rulesAndPolicy>
          </div>

          <!-- <div class="col-md-12 " *ngIf="noOfemployeeabsent!==0">
            <div class="card ">
              <div class="card-content padd-15">
                <h4 class="card-title mb-3 fontSize-20">A Few highlights of my Team</h4>
                <div class="row">
                  <div class="col-12 mb-3 mb-md-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <div class="app p-2 text-center" id="appInform">
                      <p class="h3">{{noOfemployeeabsent}}</p>
                      <h6 class="mx-auto max-w">Employees absent on {{currentdate | date:'d-MMM'}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div> -->
          <!-- <div class="col-md-12">
            <calender></calender>
          </div> -->

          <!--DAILY TASKS-->
          <!-- <div class="col-md-12">
            <daily-tasks></daily-tasks>
          </div> -->


          <!-- <div class=" col-md-12">
            <annualholiday [annualhoildaylist]="annualhoildaylist"></annualholiday>
          </div> -->

        </div>



        <!-- <div class="page-header d-xl-flex d-block">
        <div class="page-leftheader">
          <div class="page-title">Department Details
          </div>
      </div>
      </div>
      <div class="row">
        <div class="col-md-7 p-0">
         <employeeonleave></employeeonleave>
        </div>
      </div>     -->
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Employees On Leave</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <employeeonleave [empabsencelist]="empabsencelist"></employeeonleave>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</div>