// add all upcomming congif setting here , has this object is passed to backend .. where undefined propery is ignored and make sure configList.___ ( if multiple is sent )

export class configSetting {
    notificationSetting:string;
    swipeSetting:string;
    swipeSettingLeave:number;
    worknotification:number;
    deviceId:string;
    calenderDate:string;
    installationDate:string;
    joblevel:string;
    fromDate:string;
    toDate:string;
    pleave:number;
    dleave:number;
    extraTimeOut:number;
    EncashmentFromDate:string;
    EncashmentToDate:string;
    shifttime : number;
    amIn: string;
    amOut: string;
    pmIn:string;
    pmOut:string;
    nsIn:string;
    nsOut: string;

}