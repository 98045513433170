<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <!-- <div class="page-header d-xl-flex d-block"> -->
            <!-- <div class="page-leftheader">
                <div class="page-title">Attendance Approval</div>
            </div> -->
        <!-- </div> -->

        <!--End Page header-->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcForm" class="form-horizontal form-label-left">


                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="search()">
                                <label class="form-label col-xl-12 col-md-12 col-lg-12">Employee Name / ID </label>
                                <div class="col-md-10 col-sm-12 col-xs-12 mb-4">
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="text" class="form-control " name="employeename"
                                                id="employeename" (input)="getsearchtext($event.target.value)"
                                                autocomplete="off" [(ngModel)]="searchattendance" autofocus="autofocus"
                                                placeholder="Search Employee Name">

                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-12 col-xs-12">

                                    <button type="button" id="navigatetarget" class="btn btn-primary wd w-100" style="margin-top:9px !important;"
                                        (click)="search()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>
                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Row -->
        <div class="empty-div-one" style="height: 0px;" id="navigatedest"></div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <form>
                            <div class="table-responsive">
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom">
                                        <thead class="tablerow">
                                            <tr class="rowcolors stickyatTop">
                                                <th><input (change)="checkallbox($event)" type="checkbox"
                                                        style="cursor: pointer;" /></th>
                                                <th class="border-bottom-0 w-5">Employee ID</th>
                                                <th class="border-bottom-0 w-5">Name</th>
                                                <th class="border-bottom-0"> Clock Timing </th>
                                                <th class="border-bottom-0">Swipe Type</th>
                                                <th class="border-bottom-0">Status</th>
                                                <th class="border-bottom-0">Applied on</th>
                                                <th class="border-bottom-0">Remark</th>
                                                <th class="border-bottom-0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let task of attendanceDetails ; let i = index">
                                                <td><input class="unitaskbox" (change)="check_info(task ,$event)"
                                                        type="checkbox" style="cursor: pointer;" /></td>
                                                <td>{{task.employeeId}}</td>

                                                <td>
                                                    {{task.employeeName}}
                                                </td>
                                                <td>
                                                    {{task.swipetime| date: 'shortTime'}}
                                                </td>

                                                <td>{{task.swipetype === swipeintype ?
                                                    "IN" :"OUT"}}</td>


                                                <!-- <td>
                                                    <span *ngIf="task.status === 'Pending'"
                                                        class="statusPending">{{task.status}}</span>
                                                    <span *ngIf="task.status === 'Approved'"
                                                        class="statusApproved">{{task.status}}</span>
                                                    <span *ngIf="task.status === 'Partial Approve 1'"
                                                        class="statusP1">{{task.status}}</span>
                                                    <span *ngIf="task.status === 'Partial Approve 2'"
                                                        class="statusP2">{{task.status}}</span>
                                                    <span *ngIf="task.status === 'Rejected'"
                                                        class="statusReject">{{task.status}}</span>
                                                </td> -->

                                                <td>
                                                    <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                        task.status === 'Pending' ? 'bg-custom-warning text-custom-dark' :
                                                        task.status === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                        task.status === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                        task.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                        task.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                        'bg-custom-warning text-custom-dark'
                                                      " class="badge">
                                                        {{ task.status }}
                                                    </span>
                                                </td>

                                                <td>
                                                    {{task.timestamp |
                                                    date:'mediumDate'}}
                                                </td>
                                                <td><input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                        name="remark" [(ngModel)]="task.remark" class="form-control"
                                                        placeholder="Comments for Rejection"></td>
                                                <td>
                                                    <button class="btn btn-success plr" data-bs-toggle="modal"
                                                        data-bs-target="#additionalModalSwipe"
                                                        (click)="get_Attendence_info(attendanceDetails,i)">
                                                        <i class="fa fa-eye" data-bs-toggle="tooltip"
                                                            data-original-title="download"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="pro-fab btn-float-right">
                                <button type="button" class="btn btn-success"
                                    (click)="approvalhandler()">Approve</button>
                                <button type="button" class="btn btn-danger ms-2"
                                    (click)="rejecthandler()">Reject</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-->

    </div><!-- end app-content-->
    <div class="modal fade" id="additionalModalSwipe" tabindex="-1" aria-labelledby="additionalModalLabelSwipe"
        aria-hidden="true" style="display: none;">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Swipe Info as on
                        {{currentdate}}</h5>
                </div>
                <div class="modal-body" style="padding: 0px;">
                    <div class=" container-fluid">
                        <div class="mobile">
                            <div class="table-responsive"
                                style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">
                                    <thead class="tablerow">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0">Sl No</th>
                                            <th class="border-bottom-0">Clock IN/OUT</th>
                                            <th class="border-bottom-0">Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let swipe of emp_attendence_list;let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{ swipe.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                            <td>{{ swipe.swipetime| date: 'shortTime' }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        aria-label="Close">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>