<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">

    </div>
    <!-- <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
            <div class="page-title">Designation Details</div>
        </div>
    </div> -->


    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="fcForm" class="form-horizontal form-label-left" novalidate>
              <div class="x_content row">

                <div class=" row col-md-6 col-sm-6 col-xs-6">
                  <div class="form-group label-floating px-5">
                    <label class="form-label"> Designation Name </label>

                    <input type="text" class="form-control col-md-12 col-xs-12" placeholder="Enter Designation Name"
                      autocomplete="off" required="required" autofocus="true" id="textType" [(ngModel)]="name"
                      name="designationName" required #designationName="ngModel" (input)="selectDesignation($event)" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-6  justify-content-between align-items-center "
                  style="padding-top:27px">
                  <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                    style="margin-right: 3vw;margin-left:2vw" (click)="addDoc()">
                    Add
                  </button>
                  <button type="button" class="btn btn-danger w-40 view-button" type="button"
                    (click)="searchDesignation()">
                    View
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>






    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card" *ngIf="add_view_toggle === 'add_designation'">
          <div class="card-body">
            <form id="fcForm" class="form-horizontal form-label-left" novalidate>
              <div class="x_content row">

                <div class=" row col-md-6 col-sm-6 col-xs-6">
                  <div class="form-group label-floating px-5">
                    <label class="form-label"> Designation Name </label>

                    <input type="text" class="form-control col-md-12 col-xs-12" placeholder="Enter Designation Name"
                      autocomplete="off" required="required" autofocus="true" id="textType" [(ngModel)]="name"
                      name="designationName" required #designationName="ngModel" (input)="selectDesignation($event)" />
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-6">

                  <label class="form-label">Job Level</label>
                  <div>
                    <select class="form-control col-md-12 col-xs-12" type="text" data-style="btn btn-drpdwn btn-round"
                      id="reason" name="jobLevel" type="text" selected="joblevel" (change)="setjobid($event)" required>

                      <option disabled selected>select</option>
                      <option [value]="job.joblevelid
                              " *ngFor="let job of joblevel">{{job.joblevel}} </option>

                    </select>
                  </div>
                </div>

                <!-- <div class="col-md-6 col-sm-6 col-xs-6  justify-content-between align-items-center ">
                    <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                      style="margin-right: 3vw;margin-left:2vw;margin-top:1.7vw" (click)="onAddOrSaveClick()">
                      Add
                    </button>
                    <button type="button" class="btn btn-danger w-40 view-button" style="margin-top: 1.7vw;" type="button"
                      (click)="searchDesignation()">
                      View
                    </button>
                </div> -->

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="actionBar">
      <div class="clearfix"> </div>
      <div class="pro-fab">
        <button class="btn btn-success" type="button" (click)="saveDesignation()">Save</button>
        <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>
      </div>
    </div> -->



    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card" *ngIf="add_view_toggle === 'searchDesignation'">
          <!-- <div class="card-header  border-0">
            <h4 class="card-title">Employees List</h4>
          </div> -->
          <div class="card-body">
            <div class="table-responsive">
              <div class="col-xs-12 mobile">
                <div class="scrollsearchresults" infiniteScroll (scrolled)="onScroll()">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <!-- <th class="border-bottom-0 fs-6">Designation ID</th> -->
                        <th class="border-bottom-0 fs-7">Designation name</th>
                        <th class="border-bottom-0 fs-7">Created on</th>
                        <th class="border-bottom-0 fs-7">Created by</th>
                        <!-- <th class="border-bottom-0 fs-6">Payscale ID</th> -->
                        <th class="border-bottom-0 fs-7">Job Level</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let item of list">
                        <!-- <td data-th = "Designation ID">
                                   {{item.designationid }}
                                 </td> -->
                        <td data-th="Designation name">
                          {{item.name}}
                        </td>
                        <td data-th="Time Stamp">
                          {{item.timestamp |date:'mediumDate'}}
                        </td>
                        <td data-th="User ID">
                          {{item.userid}}
                        </td>
                        <!-- <td  data-th = "Payscale ID">
                                  {{item.payscaleid}}
                                </td> -->
                        <td data-th="Job Level">
                          {{item.joblevel}}
                        </td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actionBar " *ngIf="add_view_toggle == 'add_designation'">
      <div class="clearfix"> </div>
      <div class="pro-fab1">
        <button class="btn btn-success" type="button" (click)="saveDesignation()">Save</button>
        <button class="btn btn-danger ms-2" type="button" (click)="reFresh()">Clear</button>
      </div>
    </div>