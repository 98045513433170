import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.css']
})
export class PayslipComponent implements OnInit {
  date : string;
  uniqueEmployeeId :number
  constructor(private helperService: HelperService , private empsalaryservice:EmpsalaryslipService) { }

  ngOnInit(): void {
    // this.date = this.helperService.getFinancialDate(new Date());
    this.date = this.getFormatedDate(new Date())
    this.uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  }

  changeDate(selectedDate: any){
    this.date = selectedDate.target.value;
  }
  getFormattedFileName(date: Date): string {
    const month = this.getFormatedMonth(date.getMonth());
    const year = date.getFullYear();
    return `payslip-${month}-${year}-${this.uniqueEmployeeId}.zip`;
  }
  generatePayslip(){
    let date = new Date(this.date)
    
    let month = this.getFormatedMonth(date.getMonth())
    let year = date.getFullYear()
  
    this.empsalaryservice.generate_salary_slip( this.uniqueEmployeeId , year,month).subscribe(data=>{
      // console.log(" downloading zip files")
      const file = new Blob([data], { type: 'application/zip' });
    const fileName = this.getFormattedFileName(date);

    const fileURL = URL.createObjectURL(file);
    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = fileName;
    anchor.click();

  }, error => {
    console.log("Error Occured", error);
    if (error.status === 400) {
      this.alertmessages("Salary Not Generated For Selected Month ", "danger");
    }
  });
}
  getFormatedDate = (date: Date) => {
    let year = date.getFullYear();
    let month = this.getFormatedMonth(date.getMonth()+1) 

    return [year, month].join('-')
  }

  getFormatedMonth = (month : any) => {
    return (month < 10) ? '0' + month : month;
  }

  alertmessages(message:string , sign:string):void{
    let alertDiv = document.createElement('div');         
     alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message } </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function(){
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
