<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp">

        </div>


        <!-- <div class="card-body px-0"> -->
            <!-- <div class="page-leftheader my-3">
                <div class="page-title">Branch Master</div>
            </div> -->
        <!-- </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row" #branchform="ngForm">
                            <div class="row col-md-6 col-sm-7 col-xs-7 px-5">
                                <div class="form-group label-floating">
                                    <label class="form-label">Add New Branch</label>
                                    <!-- <div>
                                        <div>
                                            <input type="text" class="form-control col-md-12 col-xs-12form-control col-md-12 col-xs-12"
                                                autocomplete="off" placeholder="Enter branch Name" name="branchName"
                                                [(ngModel)]="branchName" autofocus="true">
                                        </div>
                                    </div> -->
                                    <div>
                                        <input type="text" name="branch_name" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter branch Name" autofocus="true"
                                            [(ngModel)]="branchName" required #branch_name="ngModel"
                                            [ngClass]="(!branch_name.valid && branch_name.touched) ? 'is-invalid' : ''" />
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!branch_name.valid && branch.touched)">Enter branch Name</span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-5 col-xs-5 justify-content-between align-items-start">
                                <div class="form-group label-floating">
                                    <label class="form-label">&nbsp;</label>
                                    <button *ngIf="addOrUpdate; else updateButton" type="button" id="navigatetarget"
                                        class="btn btn-primary w-40" style="margin-right: 3vw;margin-left:2vw;" (click)="addBranch()">
                                        Add
                                    </button>
                                    <ng-template #updateButton>
                                        <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                            style="margin-right: 3vw;margin-left:2vw;" (click)="updateBranch(branchId, branchName, discontinue)">
                                            Update
                                        </button>
                                    </ng-template>
                                    <button type="button" class="btn btn-danger w-40" (click)="clear()">
                                        Clear
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </div>

   
     <div class="row card-list-margin top_margin">
      <div class="col-md-12 card-list1-margin">
        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">List Of Branch</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                    id="scrolllength">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-5 fs-6 fixedcolumn">Sl.No</th>
                                <th class="border-bottom-0 w-5 fs-6 fixedcolumn secondcolumn">Branch Name</th>
                                <th class="border-bottom-0 w-5 fs-6">Action</th>
                                <th class="border-bottom-0 w-5 fs-6">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let br of branchList; let i = index">
                                <td>{{i+1}}</td>
                                <td class="col-4">{{br.name}}</td>
                                <td class="col-2">
                                    <button class="btn btn-primary btn-icon btn-sm me-2" data-method="edit" title="Edit"
                                        (click)="editBranch(br.branchId,br.name,br.discontinue)">
                                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                    <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete"
                                        title="Delete" data-bs-target="#showAlertWarning"
                                        (click)="deleteBranch(br.branchId,br.name)">
                                        <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>
                                </td>
                                <td class="form-group">
                                    <div class="form-check form-switch toggle-container">
                                        <label class="form-check-label active_pop active-label" for="discontinueToggle">
                                            Active
                                        </label>
                                        <input type="checkbox" class="form-check-input pop_1" id="discontinueToggle"
                                            [(ngModel)]="br.discontinue" name="discontinue{{i}}"
                                            (click)="inactiveEdit(br.branchId, br.name, br.discontinue)" />
                                        <label class="form-check-label inactive-label" for="discontinueToggle">
                                            Inactive
                                        </label>
                                    </div>
                                </td>
                            </tr>

                            <!-- inactivemodel  -->
                            <div class="modal fade" id="inactiveModal" tabindex="-1" role="dialog"
                                aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Inactive</h4>
                                        </div>
                                        <div class="modal-body">
                                            <p>Are you sure you want to change this branch to Inactive?</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                                (click)="clear();getBranch()">
                                                Cancel
                                            </button>
                                            <button type="button" class="btn btn-primary medium" data-bs-dismiss="modal"
                                                (click)="updateBranch(branchId, branchName, discontinue)">
                                                Yes, Inactive
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="modal fade" id="activeModal" tabindex="-1" role="dialog"
                                aria-labelledby="modalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Active</h4>
                                        </div>
                                        <div class="modal-body">
                                            <p>Are you sure you want to change this branch to Active? </p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                                                (click)="clear();getBranch()"> Cancel
                                            </button>
                                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                                (click)="updateBranch(branchId, branchName, discontinue)">
                                                Yes, Active
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
      
    </div>
     
    </div>

 </div>

</div>