import { Component, OnInit } from "@angular/core";
import { dailyTasksInfo } from "src/app/models/dailyTasks";
import { AlertService } from "src/app/services";
import { DashboardService } from "src/app/services/dashboard.service";

declare var $: any;

@Component({
  selector: "daily-tasks",
  templateUrl: "./daily-tasks.component.html",
  styleUrls: ['./daily-tasks.component.css']
})
export class DailyTasksComponent implements OnInit {
  taskList: any;
  dailyTasks: dailyTasksInfo;
  submitted: boolean;
  isCollapsed: boolean = false;
  userId:any;
  showRemainingTasks = false;
  priorityList:any = ['Low', 'High', 'Medium'];
  constructor(private dashboardService: DashboardService,
    private alertservice: AlertService) {}

  ngOnInit() {

    
    //this.userId =1;
    this.userId = sessionStorage.getItem('empId');
    console.log("The User Id is",  this.userId);
    this.dailyTasks = new dailyTasksInfo();
    this.submitted = false;

    //By deafult show drop down value as 'Low'
    this.dailyTasks.priority = 'Low';

    // Get all list of tasks of current user
    this.getListOfTasks(this.userId);
   
  }
  getListOfTasks(userId) {
    this.taskList = [];
    this.dashboardService.getListOfTasks(userId).subscribe(
      (data) => {
        console.log("list of task obtained...", data);
        data.dailyTasksList.forEach(item => {
         this.taskList.push(item)
       });
      },
      (error) => {
        const err = error.error;
        console.log(err.error);
      }
    );
  }
  clearTask(){
    this.dailyTasks.description = null ;
    this.dailyTasks.dueDate = null ; 
    this.dailyTasks.priority = null ; 
  }
  
  // Method to s ave task added by user

  saveTask(valid){
    this.submitted = true; 

    console.log("daily tasks:",this.dailyTasks)
    if (!valid) {
      return;
    }
    
    this.dashboardService.addTask(this.dailyTasks,this.userId).subscribe(
      data => {
        console.log("data", data);

        if (data.successMessages != null && data.successMessages.length !== 0)  {
          console.log("task has been added", data);

          this.submitted = false;
          this.taskList.push(data.dailyTasks)

          this.alertservice.success(data.successMessages[0]['key'])
    
          this.dailyTasks.creationDate = null;
          this.dailyTasks.description = null ;
          this.dailyTasks.dueDate = null ; 
          this.dailyTasks.priority = null ; 
          this.dailyTasks.reference = null ;
          this.dailyTasks.taskId = null ;
          this.dailyTasks.status = null ;
          this.dailyTasks.userId = null;

          // after task is added successfully, close the modal
          $('#addTaskModal').modal('toggle');
        }
       
      },
      (error) => {
        const err = error.error;
        console.log(err.error);
      }
    );
  }

  // Delete task method by paasing task id.

  deleteTask(task){
    this.dashboardService.deleteTask(task.taskId).subscribe(
      data => {
        if ( data.successMessages[0]['key'] != null ) {
          console.log("task deleted succssfully:")
          this.alertservice.success(data.successMessages[0]['key']);
          $('#deleteTaskModal').modal('toggle');
    
         }

         // Once the task is deleted refresh task list by getting task list api again
         this.getListOfTasks(this.userId);
      },
      (error) => {
        const err = error.error;
        console.log(err.error);
      }
    );
  }

  
  closeModal() {
    const modal = document.getElementById("addTaskModal");
    modal.style.display = "none";
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  toggleRemainingTasks() {
    this.showRemainingTasks = !this.showRemainingTasks;
  }

}
