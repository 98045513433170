import { DatePipe } from "@angular/common";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Noticeinfo } from "../models/Noticeinfo";
import { NoticeboardService } from "../services/noticeboard.service";
import { AlertService } from "src/app/services";



declare var $: any;
@Component({
  selector: "app-notice-board",
  templateUrl: "./notice-board.component.html",
  styleUrls: ["./notice-board.component.css"],
})
export class NoticeBoardComponent implements OnInit, OnDestroy {
  limitPara: number = 0;
  offsetPara: number = 10;
  noticeid: number;

  isEditing = false;
  isDelete = false;

  renderNoticeList: Noticeinfo[];
  savenoticeinfo: Noticeinfo;
  updatenoticeinfo: Noticeinfo;
  deleteinfo: Noticeinfo;

  submitted: boolean = false;
  editNotice: boolean = false;
  id: any;
  //   datePipe: any;

  constructor(private noticeService: NoticeboardService, private datepipe: DatePipe,private alertservice: AlertService) { }

  getnoticeSubscription: Subscription;

  ngOnInit(): void {
    this.limitPara = 13;
    this.offsetPara = 0;
    // this.submitted = false;
    this.savenoticeinfo = new Noticeinfo();
    // this.updatenoticeinfo= new Noticeinfo();

    this.getnoticelist();

  }

  saveNoticeInfo(valid) {
    this.submitted = true;
    this.isEditing = true;
    if (!valid) {
      return;
    }
    this.savenoticeinfo.title = this.savenoticeinfo.title.trim();


    if (!this.validateaDate(this.savenoticeinfo.fromdate)) {
      this.alertmessages("Date a not valid", "danger")

      return;
    } else if (!this.validateaDate(this.savenoticeinfo.todate)) {
      this.alertmessages("Date is not valid", "danger")
      return;
    }


    let isValid: boolean = this.checkfromisGreaterThantodate(this.savenoticeinfo.fromdate, this.savenoticeinfo.todate);
    if (isValid) {
      console.log(isValid)
      this.alertmessages("From date greater than To date", "danger")
      return;
    }

    console.log(this.savenoticeinfo);


    this.noticeService.saveNoticeInfo(this.savenoticeinfo).subscribe(
      (response) => {
        console.log(response);
        this.submitted = false;

        this.savenoticeinfo = new Noticeinfo();

        $("#addnoticemodals").modal("toggle");
        this.getnoticelist();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateNoticeInfo(valid) {
    this.submitted = true;
    if (!valid) {
      return;
    }
    this.savenoticeinfo.title = this.savenoticeinfo.title.trim();
    this.isEditing = false;

    if (!this.validateaDate(this.savenoticeinfo.fromdate)) {
      this.alertmessages("Date a not valid", "danger")

      return;
    } else if (!this.validateaDate(this.savenoticeinfo.todate)) {
      this.alertmessages("Date is not valid", "danger")
      return;
    }


    let isValid: boolean = this.checkfromisGreaterThantodate(this.savenoticeinfo.fromdate, this.savenoticeinfo.todate);
    if (isValid) {
      console.log(isValid)
      this.alertmessages("From date greater than To date", "danger")
      return;
    }
    console.log("update noticeinfo ")
    console.log(this.savenoticeinfo);


    this.noticeService.updateNoticeInfo(this.savenoticeinfo).subscribe(
      (response) => {
        console.log(response);
        this.submitted = false;

        this.updatenoticeinfo = new Noticeinfo();

        $("#addnoticemodals").modal("toggle");
        this.getnoticelist();
      },
      (error) => {
        console.log(error);
      }
    );
  }








  getnoticelist() {
    this.getnoticeSubscription = this.noticeService
      .getnoticeinfolist(this.limitPara, this.offsetPara)
      .subscribe(
        (response) => {
          if (this.offsetPara === 0) {
            this.renderNoticeList = response.notice_list;
          } else {
            this.renderNoticeList = this.renderNoticeList.concat(
              response.notice_list
            );
          }
          this.scrollLength()
        },
        (error) => {
          console.log(error);
        }
      );
  }

	scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight,"computed height in no ",Number(computedHeight[1]),"height ",height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2*55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}

  addNewData() {
    this.isEditing = false;
    this.savenoticeinfo = new Noticeinfo();
  }
  closemodal(e) {
    this.savenoticeinfo = new Noticeinfo();
    $("#addnoticemodals").modal("toggle");
  }


  from_date_change_listner(e, fromdate) {
    console.log(e, fromdate);
    // var timestamp = Date.parse(e);

    // if (isNaN(timestamp) == false) {
    //   var d = new Date(timestamp);

    //   console.log(d)
    // }
    // console.log(timestamp)
  }


  to_date_change_listner(e) {
    console.log(e);





    // if (this.savenoticeinfo.fromdate.length !== 0 && e.trim().length() !== 0) {
    //   let fromdate = new Date(this.savenoticeinfo.fromdate);
    //   let todate = new Date(e);

    //   if (fromdate > todate) {
    //   }
    // }
  }



  onScroll() {
    this.offsetPara = this.offsetPara + 10;
    this.getnoticelist();
  }

  limitpara() {
    this.limitPara = 0;
  }

  @HostListener("scroll", ["$event"])
  scrolled() {
    this.onScroll();
    const ele = document.getElementById("scroll");
    console.log(ele.scrollTop);
  }

  ngOnDestroy(): void {
    this.getnoticeSubscription.unsubscribe();
  }

  checkfromisGreaterThantodate(from, to): boolean {
    let fromdate: Date = new Date(from);
    let todate: Date = new Date(to);
    console.log(fromdate.valueOf() > todate.valueOf())
    if (fromdate.valueOf() > todate.valueOf()) {
      return true
    }
    return false;
  }

  validateaDate(d): boolean {
    let date: Date = new Date(d);
    let lyear = 1900;
    let currentyear = date.getFullYear();
    if (currentyear > lyear) {
      return true;
    }
    return false;

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  deleteTask(renderNoticeList){
    this.noticeService.noticeDelete(renderNoticeList).subscribe(
      data => {
        if ( data.successMessages[0]['key'] != null ) {
          console.log("notice deleted succssfully:")
          this.alertmessages("Notice Deleted", "sucess")
          $('#showAlertWarning').modal('toggle');
    
         }

         // Once the task is deleted refresh task list by getting task list api again
        //  this.getListOfTasks(this.userId);
      },
      (error) => {
        const err = error.error;
        console.log(err.error);
      }
    );
  }
  // noticeboardDeleteAlert(renderNoticeList) {
  //   this.noticeid = renderNoticeList;
  //   console.log("working  delete", this.renderNoticeList, renderNoticeList);
  //   $("#showAlertWarning").modal("toggle");
    // this.noticeService.noticeDelete(renderNoticeList).subscribe(
    //   (response) => {
    //     console.log(response);
    //     this.renderNoticeList = [];
    //     this.limitPara = 13;
    //     this.offsetPara = 0;
    //     this.getnoticelist();
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    
  // }

  noticeDelete() {
  //  renderNoticeList = this.renderNoticeList;
    console.log("working  delete",  this.id);
    // $("#showAlertWarning").modal("toggle");
    this.close();
    this.noticeService.noticeDelete( this.id).subscribe(
      (response) => {
        console.log(response);
        this.renderNoticeList = [];
        this.limitPara = 13;
        this.offsetPara = 0;
        this.getnoticelist();
      },
      (error) => {
        console.log(error);
      }
    );
    this.alertmessages("Notice Deleted Successfully", " ")
  }

  noticeboardEdit(noteData) {
    this.isEditing = true;
    console.log("clicked edit button ", this.renderNoticeList, noteData);
    this.savenoticeinfo = noteData;
    this.editNotice = true;
    $("#addnoticemodals").modal("toggle");

  }

  // noticeboardDelete(notieid) {
  //   this.isDelete = true;
  //   console.log("clicked delete yes  button ", this.renderNoticeList, notieid);
  //   this.deleteinfo = notieid;
  //   this.noticeid=notieid;
  //   // this.editNotice = true;
  //   $("#showAlertWarning").modal("toggle");
  //   this.noticeService.noticeDelete( this.noticeid).subscribe(
  //     (response) => {
  //       console.log(response);
  //       this.renderNoticeList = [];
  //       this.limitPara = 13;
  //       this.offsetPara = 0;
  //       this.getnoticelist();
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  //   this.alertmessages("Notice Deleted Successfully", " ")
  // }


  noticeboardDelete(notieid) {
    this.id = notieid;
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = "block";
    modal.style.display = 'block';
  }

  close() {
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
    modal.style.display = 'none';
  }

}
