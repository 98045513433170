<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp"></div>

        <!-- <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title">Project Budgeting</div>
        </div> -->

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">

                <div class="card-body row justify-content-between">
                    <div class="col-xs-11 col-md-11 col-sm-10 col-10" id="serachClient">
                        <div class="my-3">
                        
                            <select id="employeeMasterLucene" class="form-control" name="Employee Name" id="employeename"
                            placeholder="Search Business Name" autocomplete="off" list="employeeMasterLucene"
                            autofocus="autofocus" [(ngModel)]="contractClientDesInfo.business_name"
                            (ngModelChange)="getClientName($event)">
                                <option data-value={{emp.business_name}} *ngFor="let emp of contractClientData">
                                    {{emp.business_name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-xs-1 col-md-1 col-sm-2 col-2 my-3">
                        <button type="button" class="btn btn-primary w-100" (click)="getClientProData()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="col-md-3 col-sm-10 col-xs-10 justify-content-between d-none"
                        id="searchProjectBaseClient">
                        <select class="form-control my-3" data-style="btn btn-drpdwn btn-round"
                            name="project_location_id" placeholder="Select Location"
                            [(ngModel)]="contractClientProDesEstData.id">

                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">Select Project</option>
                            <option value={{data.id}} *ngFor="let data of contractClientProInfoData">
                                {{data.projectname}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 col-sm-2 col-xs-2 d-none justify-content-between my-3"
                        id="btnProjectBaseClient">
                        <button class="btn btn-primary btn-sm w-45" (click)="getContractClientProLocSerarch()">
                            <i class="fa fa-chevron-right" data-bs-toggle="tooltip" data-original-title="edit"></i>
                        </button>

                        <button class="btn btn-primary btn-sm w-45" (click)="getContractClientProLocView()">
                            <i class="fa fa-th-list" data-bs-toggle="tooltip" data-original-title="edit"
                                id="showProjectDataList"></i>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <div class="row d-none" *ngIf="contractClientProInfoData.length" id="projectDataList">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h4 class="card-title">{{cllientBusName}} Projects Details</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Project Name</th>
                                                <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">End Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">Remarks</th>
                                                <th class="border-bottom-0 w-5 fs-6">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let contractclient of contractClientProInfoData">
                                                <td data-th="contractclientName">{{contractclient.projectname}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.start_date| date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.end_date| date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.remarks}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary me-2"
                                                        (click)="getContractClientProLoc(contractclient)"
                                                        data-method="edit" title="Edit" id="navigateclientLocTwo">
                                                        Budgeting <i class="fa fa-chevron-right"></i>
                                                    </button>

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title">Budgeting <span *ngIf="cllientBusName">for {{cllientBusName}}</span></div>
        </div>

        <div class="row ">
            <div class="col-md-12">
                <div class="card disable-div" id="add-des-est">
                    <div class="card-content">

                        <form id="conCliForm" (ngSubmit)="contractClientDesEstForm.form.valid"
                            name="addContractClientDesEstForm" #contractClientDesEstForm="ngForm" novalidate>
                            <section>
                                <div class="card-body">

                                    <div class="col-md-12 col-sm-12 col-xs-12 pe-4 ps-4">
                                        <div class="form-group label-floating">
                                            <label class="form-label">Location</label>
                                            <div>
                                                <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                    name="project_location_id" placeholder="Select Location"
                                                    #project_location_id="ngModel"
                                                    [(ngModel)]="contractClientProDesEstData.project_location_id"
                                                    required>

                                                    <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                        Select Location</option>
                                                    <option value={{data.id}}
                                                        *ngFor="let data of contractClientProLocList">
                                                        {{data.location_name}}
                                                    </option>

                                                </select>

                                                <p *ngIf="submitted && !project_location_id.valid" class="help-block "
                                                    style="color: #ff5757;">
                                                    ⓘ Location is required
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">

                                            <div class="form-group label-floating">
                                                <label class="form-label">Designation</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        id="dGroup" name="designationid"
                                                        placeholder="Select Designation" #designation_id="ngModel"
                                                        [(ngModel)]="contractClientProDesEstData.designation_id"
                                                        required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select Designation</option>
                                                        <option value={{data.designationid}}
                                                            *ngFor="let data of empDesList">
                                                            {{data.name}}
                                                        </option>

                                                    </select>

                                                    <p *ngIf="submitted && !designation_id.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Designation is required
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Count</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Count" autofocus="true"
                                                        name="counts" #counts="ngModel"
                                                        [(ngModel)]="contractClientProDesEstData.counts" required />

                                                    <p *ngIf="submitted && !counts.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Count is required
                                                    </p>

                                                    <p *ngIf="countNumberOnly" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter only number
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Billing Rate</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Billing Rate"
                                                        autofocus="true" name="billing_rate" #billing_rate="ngModel"
                                                        [(ngModel)]="contractClientProDesEstData.billing_rate"
                                                        required />

                                                    <p *ngIf="submitted && !billing_rate.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Billing rate is required
                                                    </p>

                                                    <p *ngIf="billingRateNumberOnly" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter only number
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Unit of Measurement</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        name="uom" placeholder="Select Location" #uom="ngModel"
                                                        [(ngModel)]="contractClientProDesEstData.uom" required>

                                                        <option value="Month" [selected]="true">Month</option>
                                                    </select>

                                                    <p *ngIf="submitted && !uom.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Unit of measurement is required
                                                    </p>

                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Start Date</label>
                                                <div>
                                                    <input type="date" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Start Date"
                                                        autofocus="true" name="start_date" #start_date="ngModel"
                                                        [(ngModel)]="contractClientProDesEstData.start_date"
                                                        (ngModelChange)="checkFromAndToDate()" required />

                                                    <p *ngIf="submitted && !start_date.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Start date is required
                                                    </p>

                                                    <p *ngIf="startDate" class="help-block " style="color: #ff5757;">
                                                        ⓘ Start should be less than end date
                                                    </p>

                                                    <p *ngIf="startDateSmall" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Start date should be with in project start date
                                                    </p>

                                                    <p *ngIf="freezeDateCheckStart" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Project is freezed till {{projectFreezeDate}}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">End Date</label>
                                                <div>
                                                    <input type="date" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter End Date" autofocus="true"
                                                        name="end_date" #end_date="ngModel"
                                                        [(ngModel)]="contractClientProDesEstData.end_date"
                                                        (ngModelChange)="checkFromAndToDate()" required />

                                                    <p *ngIf="submitted && !end_date.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ End date is required
                                                    </p>

                                                    <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                        ⓘ End date should be greater than start date
                                                    </p>

                                                    <p *ngIf="endDateGreat" class="help-block " style="color: #ff5757;">
                                                        ⓘ End date should be with in project end date
                                                    </p>

                                                    <p *ngIf="freezeDateCheckEnd" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Project is freezed till {{projectFreezeDate}}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="actionBar pe-4 ps-4">
                                        <div class="clearfix"> </div>
                                        <div>
                                            <button class="btn btn-success" type="button" id="save-btn"
                                                style="display: inline-block"
                                                (click)="saveContractClientProDesEstData(contractClientDesEstForm.form.valid)">Save</button>
                                            <button class="btn btn-success" type="button" id="update-btn"
                                                style="display: none"
                                                (click)="updateContractClientProDesEstData(contractClientDesEstForm.form.valid)">Update</button>
                                            <button class="btn btn-danger ms-2" type="button"
                                                (click)="clearContractClientProDesEstData()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="clearfix"> </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="contractClientProDesEstDataList.length">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Budgeting Details</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Location</th>
                                                <th class="border-bottom-0 w-5 fs-6">Designation</th>
                                                <th class="border-bottom-0 w-5 fs-6">Count</th>
                                                <th class="border-bottom-0 w-5 fs-6">Billing Rate</th>
                                                <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">End Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">UOM</th>
                                                <th class="border-bottom-0 w-5 fs-6">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of contractClientProDesEstDataList">
                                                <td data-th="contractclientName">{{data.location_name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.designation_name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.counts}}
                                                </td>
                                                <td data-th="contractclientName">{{data.billing_rate}}
                                                </td>
                                                <td data-th="contractclientName">{{data.start_date| date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.end_date | date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.uom}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="contractClientProDesEstEdit(data)" data-method="edit"
                                                        title="Edit" id="navigateclientLocTwo">
                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>

                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        (click)="contractClientProDesEstDeleteDataStore(data)"
                                                        data-method="delete" title="Delete"
                                                        data-bs-target="#showAlertVer">
                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="showAlertWarning" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-alert-circle fs-80 text-warning lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger mb-3">Are you sure you want to delete this data?</h4>
                <!-- <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p> -->

                <button aria-label="Close" class="btn btn-danger pd-x-25 mx-1" data-bs-dismiss="modal"
                    (click)="contractClientProDesEstDeleteData()">Delete</button>
                <button aria-label="Close" class="btn btn-primary pd-x-25 mx-1" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-link fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">Okay!</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantUpdateFreeze" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <p class="mg-b-20 mg-x-20">Freezed till {{projectFreezeDate| date: 'mediumDate'}}! Data can't be updated</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">Okay!</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDeleteFreeze" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <p class="mg-b-20 mg-x-20">This data can't be deleted</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">Okay!</button>
            </div>
        </div>
    </div>
</div>